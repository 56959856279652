/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import React, { useEffect, useRef, useState } from 'react'
import BurstWrapper from '../Wrappers/BurstWrapper'
import {
  type Comment,
  type Video,
  type VideoSource
} from '../../types/videoInterface'
import { motion, AnimatePresence } from 'framer-motion'
import { getVideoById } from '../../core/requests/getVideoById'
import { useNavigate, useParams } from 'react-router-dom'
import { getVideoComments } from '../../core/requests/getVideoComments'
import { mapVideoCommentsToCommentProps } from '../../core/mappers/mapVideoCommentsToCommentProps'
import { getAccountAsUserFromLocalStorage } from '../../helpers/getAccountFromLocalStorage'
import { type VideoRatingResponse, type RateVideo } from '../../types/RateVideoRequest'
import { rateVideo } from '../../core/requests/rateVideo'
import { createVideoComment } from '../../core/requests/createVideoComment'
import { getMyRatesOfAVideo } from '../../core/requests/getMyRatesOfAVideo'
import { variants } from '../../constants/variants.motion'
import { defaultVideoQueryParams, getVideoList } from '../../core/requests/getVideoList'
import { type AllVideosQueryParams } from '../../types/QueryParametersTypes'
import { useAppSelector } from '../../context/store.config'
import { getIsCommentSectionShown } from '../../context/commentsSlice/selectors'
import { getTokens } from '../../context/authSlice/selectors'
import { type AuthToken } from '../../types/LoginResponse'
import { jsonParse } from '../../utils/jsonParse'
import { Swiper, type SwiperClass, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { Autoplay, Mousewheel, Pagination, Virtual } from 'swiper/modules'
import { isDesktop, isMobile } from 'react-device-detect'

interface VideoSwiperProps {
  videos: Video[] | VideoSource[] // Array of simple video sources
  currentVideo: Video // Detailed data for the currently selected video
  navigate?: (path: string) => void
}

const VideoSwiper: React.FC<VideoSwiperProps> = ({ videos: currentVideos, currentVideo }) => {
  const navigate = useNavigate()
  const { videoId } = useParams()
  const [currentIndex, setCurrentIndex] = useState(0)
  const [direction, setDirection] = useState<string>('next')
  const [video, setVideo] = useState<Video>(currentVideo)
  const [comments, setComments] = useState<Comment[]>([])
  const [videos, setVideos] = useState<Video[] | VideoSource[]>(currentVideos)
  const [lastSwipeTime, setLastSwipeTime] = useState(0)
  const touchStart = useRef<{ x: number, y: number, time: number } | null>(
    null
  )
  const [isLoading, setIsLoading] = useState(true)
  const [myRatingOfVideo, setMyRatingOfVideo] = useState<VideoRatingResponse>({
    videoId: 1,
    rating: ''
  })
  const videoCache = useRef<Record<string, { video: Video, comments: Comment[], myRatingOfVideo: VideoRatingResponse }>>({})
  const [isTransitioning, setIsTransitioning] = useState(false)
  const isCommentSectionShown = useAppSelector(getIsCommentSectionShown)
  const token = useAppSelector(getTokens)
  const [videosWithDetails, setVideosWithDetails] = useState<Video[]>([])
  const [nextIndex, setNextIndex] = useState(1)
  const [prevIndex, setPrevIndex] = useState(videosWithDetails.length - 1)

  useEffect(() => {
    const fetchVideoWithDetails = async () => {
      const tokens = localStorage.getItem('token')
      const localStorageTokens: AuthToken = jsonParse(tokens)

      let response: Video[] | undefined = []
      if ((localStorageTokens && localStorageTokens.access_token) || (token?.access_token)) {
        response = await getVideoList({ ...defaultVideoQueryParams, excludeAlreadyWatched: true }, true)
      } else {
        response = await getVideoList(defaultVideoQueryParams, false)
      }
      if (response && response.length > 0) {
        setPrevIndex(response.length - 1)
        const videoDetailsPromises = response.map(async (video) => {
          try {
            const videoResponse = await getVideoById(video.id)
            const commentsResponse = await getVideoComments(videoResponse!.id)
            const mappedComments = mapVideoCommentsToCommentProps(commentsResponse)
            const myVideoRate = await getMyRatesOfAVideo(video.id)

            return {
              ...videoResponse,
              comments: mappedComments ?? [],
              rate: myVideoRate ?? null
            }
          } catch (error) {
            console.error('Failed to fetch video or comments:', error)
            return null
          }
        })

        const videosWithData = await Promise.all(videoDetailsPromises)
        const validVideos = videosWithData.filter((video) => video !== null)

        setVideosWithDetails(validVideos as Video[])

        // Move the video with the ID from the URL to the first position in the array
        if (videoId) {
          const initialIndex = validVideos.findIndex(video => video?.id === videoId)
          navigate(`/burst/${videoId}`, { replace: true })
          if (initialIndex !== -1) {
            const [videoToMove] = validVideos.splice(initialIndex, 1)
            validVideos.unshift(videoToMove)
            setCurrentIndex(0)
          } else {
            try {
              // Video not found, fetch it from the API
              const videoResponse = await getVideoById(videoId)

              if (!videoResponse) {
                console.error(`Video with ID ${videoId} not found`)
                return
              }

              const commentsResponse = await getVideoComments(videoResponse.id)
              const mappedComments = mapVideoCommentsToCommentProps(commentsResponse)
              const myVideoRate = await getMyRatesOfAVideo(videoResponse.id)

              const currentVideo = {
                ...videoResponse,
                comments: mappedComments ?? [],
                rate: myVideoRate ?? undefined
              }

              setVideosWithDetails(prev => [currentVideo, ...prev])
            } catch (error) {
              console.error('Error fetching video data:', error)
            }
          }
        }
      }

      setIsLoading(false)
    }

    fetchVideoWithDetails()
  }, [currentVideos, token])

  const fetchMoreVideos = (currentNewIndex: number) => {
    const queryParams: AllVideosQueryParams = {
      start: currentNewIndex,
      count: currentNewIndex + 5,
      sort: '-best',
      skipCount: false,
      nsfw: false,
      tagsOneOf: ['bursts']
    }

    const tokens = localStorage.getItem('token')
    const localStorageTokens: Pick<AuthToken, 'access_token'> = jsonParse(tokens)

    let authenticated = false

    // add this condition if in App.tsx line 234 is uncommented, ((localStorageTokens && localStorageTokens.access_token) || (token && token.access_token)) && videos.length > 0
    if ((localStorageTokens && localStorageTokens.access_token) || (token?.access_token)) {
      authenticated = true
      queryParams.excludeAlreadyWatched = true
    }

    getVideoList(queryParams, authenticated).then(async res => {
      if (res) {
        if (res && res.length > 0) {
          const videoDetailsPromises = res.map(async (video) => {
            try {
              const videoResponse = await getVideoById(video.id)
              const commentsResponse = await getVideoComments(videoResponse!.id)
              const mappedComments = mapVideoCommentsToCommentProps(commentsResponse)
              const myVideoRate = await getMyRatesOfAVideo(video.id)

              return {
                ...videoResponse,
                comments: mappedComments ?? [],
                rate: myVideoRate ?? null
              }
            } catch (error) {
              console.error('Failed to fetch video or comments:', error)
              return null
            }
          })

          const videosWithData = await Promise.all(videoDetailsPromises)
          const validVideos = videosWithData.filter((video) => video !== null)
          setVideosWithDetails((prevState: any) => {
            const existingIds = prevState.map((video: any) => video.id)
            const newVideos = validVideos.filter((video: any) => !existingIds.includes(video.id))

            return [...prevState, ...newVideos]
          })
        }
      }
    })
  }

  const setCommentThreadText = (text: string) => {
    if (text !== '' && video) {
      (async () => await createVideoComment(video?.id, { text }))()
    }
  }

  const onRateVideo = (rate: RateVideo) => {
    if (video) {
      (async () => {
        return await rateVideo(video?.id, rate)
      })()
    }
  }

  const handleSwipe = (direction: 'next' | 'prev') => (swiper: SwiperClass) => {
    setDirection(direction)
    const currentTime = Date.now()
    const timeSinceLastSwipe = currentTime - lastSwipeTime
    if (timeSinceLastSwipe < 1000) {
      return
    }
    setIsTransitioning(true)
    setLastSwipeTime(currentTime)
    let newIndex = swiper.activeIndex
    setNextIndex((swiper.activeIndex + 1) % videos.length)
    setPrevIndex((swiper.activeIndex - 1 + videos.length) % videos.length)

    if (direction === 'next') {
      newIndex = swiper.activeIndex

      if (newIndex === videosWithDetails.length - 1) {
        fetchMoreVideos(newIndex)
      }

      console.log('swipe up', newIndex, videosWithDetails.length, newIndex + 5, newIndex === videosWithDetails.length - 1)
      console.log('newIndex', newIndex)

      setCurrentIndex(newIndex)
    } else if (direction === 'prev') {
      if (currentIndex === 0) {
        return
      }
      newIndex = swiper.activeIndex
      setCurrentIndex(newIndex)
    }

    console.log('direction', direction)
    console.log('active index', swiper.activeIndex)
    console.log('videosWithDetails[newIndex].id', videosWithDetails[newIndex].id)

    navigate(`/burst/${videosWithDetails[newIndex].id}`, { replace: true })
  }

  let startY: number

  const handleMouseDown = (e: { clientY: number }) => {
    startY = e.clientY
  }

  const handleMouseUp = (e: { clientY: number }) => {
    const endY = e.clientY
    if (startY > endY + 10) {
      // handleSwipe('up')
    } else if (startY < endY - 10) {
      // handleSwipe('down')
    }
  }

  const handleTouchStart = (e: any) => {
    if (isCommentSectionShown) {
      // e.preventDefault()
      e.stopPropagation()

      return
    }

    const touch = e.touches
    touchStart.current = {
      x: touch.startX,
      y: touch.startY,
      time: Date.now()
    }
  }

  const handleTouchEnd = (e: any) => {
    if (isCommentSectionShown) {
      // e.preventDefault()
      e.stopPropagation()

      return
    }

    const endTouch = e.touches
    if (touchStart.current) {
      const { x, y, time } = touchStart.current
      const dx = endTouch.currentX - x
      const dy = endTouch.currentY - y
      const dt = Date.now() - time

      const swipeThreshold = 30 // Minimum movement to be considered a swipe
      const swipeDuration = 500 // Maximum duration of a swipe

      if (dt < swipeDuration && Math.abs(dy) > swipeThreshold) {
        const swipeDirection = dy > 0 ? 'down' : 'up'
        // handleSwipe(swipeDirection)
      }

      touchStart.current = null // Reset after handling swipe
    }
  }

  return (
    <div
      // onMouseDown={handleMouseDown}
      // onMouseUp={handleMouseUp}
      // onTouchStart={handleTouchStart}
      // onTouchEnd={handleTouchEnd}
    >
      {
        videosWithDetails.length > 0 && (
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            effect='slide'
            direction="vertical"
            // onSlideChange={swiper => {
            //   handleSwipe(swiper)
            // }}
            onSlideNextTransitionStart={handleSwipe('next')}
            onSlidePrevTransitionStart={handleSwipe('prev')}
            style={{ height: '100dvh', maxHeight: !isMobile ? '92dvh' : '100dvh', width: '100dvw' }}
            modules={[Pagination, Virtual, Mousewheel]}
            allowTouchMove={!isDesktop}
            mousewheel={isDesktop}
            // onMouseDown={handleMouseDown}
            // onMouseUp={handleMouseUp}
            // onTouchStart={handleTouchStart}
            // onTouchEnd={handleTouchEnd}
          >
            {videosWithDetails.map((currentVideo, index) => (
              <SwiperSlide key={index}>
                {({ isActive }) => (
                  <>
                    {currentVideo && currentVideo.id !== '' && (
                      <div
                        key={currentIndex}
                        onClick={e => {
                          if (isCommentSectionShown) {
                            e.preventDefault()
                            e.stopPropagation()
                          }
                        }}
                        onTouchStart={e => {
                          if (isCommentSectionShown) {
                            e.preventDefault()
                            e.stopPropagation()
                          }
                        }}
                        onTouchEnd={e => {
                          if (isCommentSectionShown) {
                            e.preventDefault()
                            e.stopPropagation()
                          }
                        }}
                        className={isCommentSectionShown ? 'overflow-hidden' : ''}
                      >
                        {/* @ts-expect-error */}
                        <BurstWrapper
                          key={currentIndex}
                          currentIndex={currentIndex}
                          src={currentVideo.url}
                          myRatingOfVideo={currentVideo.rate!}
                          currentVideo={currentVideo}
                          comments={currentVideo.comments ?? []}
                          user={getAccountAsUserFromLocalStorage()}
                          onRateVideo={onRateVideo}
                          setCommentThreadText={setCommentThreadText}
                          loading={isLoading}
                          autoplay={isActive}
                        />
                      </div>
                    )}
                  </>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        )
      }
    </div>
  )
}

export default VideoSwiper
