export const jsonParse = (data: string | null | undefined) => {
  if (data === undefined || data === null || data === '' || data === '{}') {
    console.log('jsonParse', data)
    return data
  } else {
    return JSON.parse(data)
  }
}

export const jsonStringify = (data: any) => {
  if (data === undefined || data === null || data === '' || data === '{}') {
    return 'undefined'
  } else {
    return JSON.stringify(data)
  }
}
