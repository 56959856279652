import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { initialVideoState } from './initialVideoState'
import { type Video } from '../../types/videoInterface'

export const VideoSlice = createSlice({
  name: 'videoSlice',
  initialState: initialVideoState,
  reducers: {
    setVideos: (state, action: PayloadAction<Video[]>) => {
      state.videos = action.payload
    },
    setVideo: (state, action: PayloadAction<Video>) => {
      state.video = action.payload
    }
  }
})

export default VideoSlice.reducer
