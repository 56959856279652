import React, { useState } from 'react'

interface CustomDropdownProps {
  options: string[]
  selected: string
  onSelect: (value: string) => void
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({ options, selected, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleSelect = (value: string) => {
    onSelect(value)
    setIsOpen(false)
  }

  return (
    <div className="relative">
      <div
        className="block w-full mt-1 p-2 bg-gray-700 rounded cursor-pointer"
        onClick={() => { setIsOpen(!isOpen) }}
      >
        {selected}
      </div>
      {isOpen && (
        <div className="absolute left-0 mt-1 w-full bg-gray-700 rounded shadow-lg z-10">
          {options.map(option => (
            <div
              key={option}
              className="p-2 hover:bg-gray-600 hover:text-white cursor-pointer"
              onClick={() => { handleSelect(option) }}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default CustomDropdown
