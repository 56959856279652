/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { type AllVideosQueryParams } from '../../types/QueryParametersTypes'
import { convertToQueryParams } from '../../utils/convertToQueryParams'
import { shuffleVideosFisherYates } from '../../utils/shuffleVideosFisherYates'
import { videoService } from '../services'

export const defaultVideoQueryParams: AllVideosQueryParams = {
  start: 0,
  count: 10,
  sort: '-best',
  skipCount: true,
  nsfw: false,
  tagsOneOf: ['bursts']
}

export const getVideoList = async (params: AllVideosQueryParams = defaultVideoQueryParams, authenticated: boolean) => {
  const query = convertToQueryParams(params)

  // random videos by Fisher-Yates, if not logged in excludeAlreadyWatched will be undefined (falsy)
  if (!authenticated && !params?.excludeAlreadyWatched) {
    const response = await videoService.getVideoList(query)
    if (response && response.length > 0) {
      return shuffleVideosFisherYates(response)
    }
  }

  return await videoService.getVideoList(query)
}
