import { type CommentData, type CommentResponse } from '../../types/CommentResponse'
import { type CreateCommentRequest } from '../../types/CreateCommentRequest'
import { type CommentsQueryParams } from '../../types/QueryParametersTypes'
import { convertToQueryParams } from '../../utils/convertToQueryParams'
import { type IApiClientToken } from '../api/Types'
import { ApiClient } from '../api/apiClient'
import uriObject from '../uris/index.uri'

export interface ICommentApiClient extends IApiClientToken {
  getVideoComments: (videoShortUUID: string, query: string) => Promise<CommentResponse | undefined>
  createVideoComment: (videoShortUUID: string, text: CreateCommentRequest) => Promise<CommentData | undefined>
}

export class CommentApiClient extends ApiClient implements ICommentApiClient {
  async getVideoComments (videoShortUUID: string, query: string): Promise<CommentResponse | undefined> {
    try {
      const response = await this.get<CommentResponse>(`${this.apiBase}${uriObject.videoUris.videos}/${videoShortUUID}${uriObject.commentsUris.commentThreads}${query}`)
      return response
    } catch (error) {
      console.error('ERR::GETVIDEOCOMMENTS', error)
    }
  }

  async createVideoComment (videoShortUUID: string, text: CreateCommentRequest): Promise<CommentData | undefined> {
    try {
      const response = await this.post<CreateCommentRequest, CommentData>(`${this.apiBase}${uriObject.videoUris.videos}/${videoShortUUID}${uriObject.commentsUris.commentThreads}`, text)
      return response
    } catch (error) {
      console.error('ERR::CREATEVIDEOCOMMENT', error)
    }
  }
}

export class CommentService {
  readonly client: ICommentApiClient

  constructor (client: ICommentApiClient) {
    this.client = client
  }

  async getVideoComments (videoShortUUID: string, query: CommentsQueryParams): Promise<CommentResponse | undefined> {
    const queryParameters = convertToQueryParams(query)
    return await this.client.getVideoComments(videoShortUUID, queryParameters)
  }

  async createVideoComment (videoShortUUID: string, text: CreateCommentRequest): Promise<CommentData | undefined> {
    return await this.client.createVideoComment(videoShortUUID, text)
  }
}
