import { type CommentsQueryParams } from '../../types/QueryParametersTypes'
import { commentService } from '../services'

const defaultInitCommentsQueryParams: CommentsQueryParams = {
  start: 0,
  count: 25,
  sort: '-createdAt',
  isDeleted: false
}

export const getVideoComments = async (videoShortUUID: string, query: CommentsQueryParams = defaultInitCommentsQueryParams) => {
  return await commentService.getVideoComments(videoShortUUID, query)
}
