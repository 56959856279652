import { TUBE_BASE_URL } from '../../constants/app.enums'
import { type VideoDetail } from '../../types/GetVideoResponse'
import { type Video } from '../../types/videoInterface'

export const mapToCurrentVideo = (video: VideoDetail) => {
  const baseUrl = process.env.REACT_APP_PARLER_PLAY_BASE_URL
  const mappedVideo: Video = {
    id: video.shortUUID,
    title: video.name,
    description: video.description,
    url: video?.streamingPlaylists[0]?.files[0]?.fileUrl ?? video?.files[0]?.fileUrl,
    likeCount: video.likes,
    userDetails: {
      id: video.account.userId,
      username: video.account.displayName,
      avatarUrl: video.account.avatars.length > 0 ? `${process.env.REACT_APP_PARLER_PLAY_BASE_URL}${video.account.avatars[0].path}` : '',
      profileUrl: video?.account?.url?.includes(TUBE_BASE_URL) ? video?.account?.url.replaceAll(TUBE_BASE_URL, baseUrl!) : video?.account?.url,
      name: video.account.name
    },
    songDetails: {
      title: 'Sample Song Title',
      artist: 'Sample Artist'
    },
    previewPath: video.thumbnailPath,
    total: video.total,
    name: ''
  }

  return mappedVideo
}
