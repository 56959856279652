import React from 'react'

interface CustomToastProps {
  text: string
}

const DiscreteToast: React.FC<CustomToastProps> = ({ text }) => {
  return (
    <div className="flex items-center px-3 py-3 border border-[#737373] rounded-xl shadow-sm text-white bg-[#262626]" style={{ zIndex: 9999999999 }}>
        <div className="mr-3">
          <img src='/CopyIcon.svg' alt='Copied' className="w-6 h-6" /> {/* Replace with your icon path */}
        </div>
        <span className="text-base font-normal">{text}</span>
    </div>
  )
}

export default DiscreteToast
