import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { initialCommentsState } from './initialCommentsState'

export const CommentsSlice = createSlice({
  name: 'commentsSlice',
  initialState: initialCommentsState,
  reducers: {
    setIsCommentSectionShown: (state, action: PayloadAction<boolean>) => {
      state.isCommentSectionShown = action.payload
    },
    setCurrentLoggedUserId: (state, action: PayloadAction<number>) => {
      state.currentUserId = action.payload
    }
  }
})

export default CommentsSlice.reducer
