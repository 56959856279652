const videoUris = {
  videos: '/videos',
  rateVideo: '/rate',
  myVideoLikes: '/users/me',
  myRateOfTheVideo: '/rating',
  playlists: '/playlists',
  addVideo: '/add-video',
  notifyUserWatchingVideo: '/views'
}

export default videoUris
