/* eslint-disable @typescript-eslint/no-unsafe-argument */
export const convertToQueryParams = (params: Record<string, any>): string => {
  const queryString = Object.entries(params)
    .map(([key, value], index) => {
      const prefix = index === 0 ? '?' : '&' // Use '?' for the first parameter, '&' for others
      return `${prefix}${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    })
    .join('')

  return queryString
}
