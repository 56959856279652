/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-trailing-spaces */
import React from 'react'

export interface LoginDialogProps {
    isOpen: boolean
    onClickLogin: () => void
    closeDialog: (e: any) => void
}

const LoginDialog: React.FC<LoginDialogProps> = ({ isOpen = false, onClickLogin, closeDialog }) => {
  const handleCloseDialog = (e: any) => {
    closeDialog(e)
  }

  const handleLoginClick = () => {
    onClickLogin()
  }

  return (
    <div className={`fixed inset-0 z-5510 overflow-y-auto ${isOpen ? '' : 'hidden'}`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex justify-center items-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {/* Background overlay, simulate modal effect */}
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" onClick={handleCloseDialog}></div>
        {/* Modal panel */}
        <div className="inline-block align-bottom bg-[#262626] rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 w-full">
          <div className="sm:flex sm:items-start">
            <div className="mt-3 text-center sm:mt-0 sm:ml-0 sm:text-left w-full">
              <div className='flex justify-between items-center pb-2'>
                <div className='d-flex justify-content-start'>
                    <h3 className="text-lg leading-6 font-semibold text-white" id="modal-title">
                        You are not logged in
                    </h3>
                </div>
                <button 
                    className='d-flex justify-content-end focus:outline-none text-white bg-transparent hover:bg-white/10 rounded-full p-2'
                    onClick={handleCloseDialog}
                >
                    <img src={'/CloseIcon.svg'} alt="Close dialog"/>
                </button>
              </div>
              <div className="mt-2">
                <div className="text-sm text-white">
                    <div className="flex mt-4 items-stretch">
                        <p className='text-base font-normal text-white'>Please LogIn in order to comment</p>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div className='flex justify-end space-x-4'>
            <button className="bg-white bg-opacity-10 h-10 w-20 shadow-sm uppercase text-white px-4 flex items-center justify-center" onClick={handleLoginClick}>
                Login
            </button>

            <button className="bg-white bg-opacity-10 h-10 w-20 shadow-sm uppercase text-white px-4 flex items-center justify-center" onClick={handleCloseDialog}>
                Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginDialog
