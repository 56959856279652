import { type Video } from '../../types/videoInterface'

export interface InitialVideoState {
  videos: Video[]
  video: Video
}

export const initialVideoState: InitialVideoState = {
  videos: [],
  video: {
    id: '',
    title: '',
    description: '',
    url: '',
    // @ts-expect-error
    userDetails: {
      id: 0,
      username: '',
      avatarUrl: '',
      profileUrl: undefined
    },
    songDetails: {
      title: '',
      artist: ''
    },
    previewPath: ''
  }
}
