import React, { useState } from 'react'
import CustomDropdown from './CustomDropdown'

interface ReportFormProps {
  onClose: () => void
  onSubmit: (reportType: string, message: string) => void
}

const reportTypes = [
  'violentOrRepulsive',
  'hatefulOrAbusive',
  'spamOrMisleading',
  'privacy',
  'serverRules',
  'thumbnails',
  'captions'
]

const ReportForm: React.FC<ReportFormProps> = ({ onClose, onSubmit }) => {
  const [reportType, setReportType] = useState(reportTypes[0])
  const [message, setMessage] = useState('Report')

  const handleSubmit = () => {
    onSubmit(reportType, message)
    onClose()
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-gray-800 text-white rounded-lg p-4 shadow-lg w-96">
        <h2 className="text-lg font-bold mb-4">Report Content</h2>
        <label className="block mb-2">
          Report Type:
          <CustomDropdown
            options={reportTypes}
            selected={reportType}
            onSelect={setReportType}
          />
        </label>
        <label className="block mb-4">
          Message:
          <textarea
            value={message}
            onChange={(e) => { setMessage(e.target.value) }}
            className="block w-full mt-1 p-2 bg-gray-700 rounded"
            rows={4}
          />
        </label>
        <div className="flex justify-end">
          <button onClick={onClose} className="mr-2 p-2 bg-red-500 rounded">Cancel</button>
          <button onClick={handleSubmit} className="p-2 bg-blue-500 rounded">Submit</button>
        </div>
      </div>
    </div>
  )
}

export default ReportForm
