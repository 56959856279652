import { combineReducers } from '@reduxjs/toolkit'
import videoReducer from './videoSlice/videoSlice'
import commentsReducer from './commentsSlice/commentsSlice'
import authReducer from './authSlice/authSlice'

export const rootReducer = combineReducers({
  authReducer,
  videoReducer,
  commentsReducer
})

export type RootState = ReturnType<typeof rootReducer>
