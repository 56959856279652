import authUris from './auth.uris'
import videoUris from './video.uris'
import commentsUris from './comments.uris'

const uriObject = {
  authUris,
  videoUris,
  commentsUris
}

export default uriObject
