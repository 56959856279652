/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { type AxiosResponse } from 'axios'
import { type VideoDetail } from '../../types/GetVideoResponse'
import { type ListVideosResponse } from '../../types/ListVideoResponse'
import { type VideoRatingResponse, type RateVideo } from '../../types/RateVideoRequest'
import { type Video } from '../../types/videoInterface'
import { type IApiClientToken } from '../api/Types'
import { ApiClient } from '../api/apiClient'
import { mapToCurrentVideo } from '../mappers/mapVideoDetailToVideoData'
import uriObject from '../uris/index.uri'
import { type NotifyUserWatchingVideoRequest } from '../../types/NotifyUserWatchingVideoRequest'

export interface IVideoApiClient extends IApiClientToken {
  report: (reportPayload: { reason: string, predefinedReasons: string[], video: { id: number }, comment: { id: number } | undefined, account: { id: number } | undefined }) => unknown
  getVideoList: (query: string) => Promise<ListVideosResponse | undefined>
  getVideoById: (shortUUID: string) => Promise<VideoDetail | undefined>
  rateVideo: (videoShortUUId: string, rate: RateVideo) => Promise<any>
  getMyRatesOfAVideo: (videoShortUUId: string) => Promise<VideoRatingResponse | undefined>
  addVideoToPlaylist: (playlistId: number, videoId: string | number, startTimestamp?: number, stopTimestamp?: number) => Promise<any>
  blockAccount: (accountName: string) => Promise<void>
  deleteComment: (id: string | number, commentId: number) => Promise<void>
  notifyUserIsWatchingVideo: (videoId: string, notifyUserIsWatchingVideoPayload: NotifyUserWatchingVideoRequest) => Promise<void>
}
export class VideoApiClient extends ApiClient implements IVideoApiClient {
  async deleteComment (id: string | number, commentId: number): Promise<void> {
    try {
      await this.delete<void>(`${this.apiBase}/videos/${id}/comments/${commentId}`)
    } catch (error) {
      console.error('ERR::DELETECOMMENT', error)
      throw error
    }
  }

  async notifyUserIsWatchingVideo (videoId: string, notifyUserIsWatchingVideoPayload: NotifyUserWatchingVideoRequest): Promise<void> {
    try {
      await this.post<NotifyUserWatchingVideoRequest, void>(`${this.apiBase}${uriObject.videoUris.videos}/${videoId}${uriObject.videoUris.notifyUserWatchingVideo}`,
        notifyUserIsWatchingVideoPayload
      )
    } catch (error) {
      console.error('ERR::NOTIFY_SERVER_WATCHING_VIDEO', error)
      throw error
    }
  }

  async report (reportPayload: any): Promise<AxiosResponse<any>> {
    try {
      const response = await this.post<any, any>(`${this.apiBase}/abuses`, reportPayload)
      return response
    } catch (error) {
      console.error('ERR::REPORTVIDEO', error)
      throw error
    }
  }

  async blockAccount (accountName: string): Promise<void> {
    try {
      const payload = { accountName }
      await this.post<any, void>(`${this.apiBase}/users/me/blocklist/accounts`, payload)
    } catch (error) {
      console.error('ERR::BLOCKACCOUNT', error)
      throw error
    }
  }

  async addVideoToPlaylist (playlistId: number, videoId: string | number, startTimestamp?: number, stopTimestamp?: number): Promise<void> {
    try {
      const payload = {
        videoId,
        startTimestamp,
        stopTimestamp
      }
      await this.post<any, void>(`${this.apiBase}${uriObject.videoUris.playlists}/${playlistId}${uriObject.videoUris.addVideo}`, payload)
    } catch (error) {
      console.error('ERR::ADDVIDEOTOPLAYLIST', error)
      throw error
    }
  }

  async getMyRatesOfAVideo (videoShortUUId: string): Promise<VideoRatingResponse | undefined> {
    try {
      const response = await this.get<VideoRatingResponse>(`${uriObject.videoUris.myVideoLikes}${uriObject.videoUris.videos}/${videoShortUUId}${uriObject.videoUris.myRateOfTheVideo}`)
      return response
    } catch (error) {
      console.error('ERR::GETMYLIKESOFAVIDEO', error)
      throw error
    }
  }

  async getVideoList (query: string): Promise<ListVideosResponse | undefined> {
    try {
      const response = await this.get<ListVideosResponse>(`${this.apiBase}${uriObject.videoUris.videos}${query}`)
      return response
    } catch (error) {
      console.error('ERR::GETVIDEOLIST', error)
      throw error
    }
  }

  async getVideoById (shortUUID: string): Promise<VideoDetail | undefined> {
    try {
      const response = await this.get<VideoDetail>(`${this.apiBase}${uriObject.videoUris.videos}/${shortUUID}`)
      return response
    } catch (error) {
      console.error('ERR::GETVIDEO', error)
      throw error
    }
  }

  async rateVideo (videoShortUUId: string, rate: RateVideo): Promise<any> {
    try {
      return await this.put<RateVideo, any>(`${this.apiBase}${uriObject.videoUris.videos}/${videoShortUUId}${uriObject.videoUris.rateVideo}`, rate)
    } catch (error) {
      console.error('ERR::RATEVIDEO', error)
      throw error
    }
  }
}

export class VideoService {
  readonly client: IVideoApiClient

  constructor (client: IVideoApiClient) {
    this.client = client
  }

  async getVideoList (query: string): Promise<Video[] | undefined> {
    const response = await this.client.getVideoList(query)
    const videos = response?.data
    return videos?.map(video => {
      const mappedVideo: Video = {
        id: video.shortUUID,
        title: video.name,
        description: video.description,
        url: '',
        userDetails: {
          id: video.account.id,
          username: video.account.name,
          avatarUrl: video.account.avatars.length > 0 ? `${process.env.REACT_APP_PARLER_PLAY_BASE_URL}${video.account.avatars[0].path}` : '',
          name: ''
        },
        songDetails: {
          title: '',
          artist: ''
        },
        previewPath: video.thumbnailPath,
        total: response?.total,
        name: ''
      }

      return mappedVideo
    })
  }

  async getVideoById (shortUUID: string): Promise<Video | undefined> {
    const video = await this.client.getVideoById(shortUUID)
    if (video) {
      const mappedVideo = mapToCurrentVideo(video)
      return mappedVideo
    }
    return undefined
  }

  async rateVideo (videoShortUUId: string, rate: RateVideo): Promise<any> {
    return await this.client.rateVideo(videoShortUUId, rate)
  }

  async getMyRatesOfAVideo (videoShortUUId: string): Promise<VideoRatingResponse | undefined> {
    if (!this.client.getToken()) {
      return {
        videoId: 0,
        rating: 'none'
      }
    }
    return await this.client.getMyRatesOfAVideo(videoShortUUId)
  }

  async addVideoToPlaylist (playlistId: number, videoId: string | number, startTimestamp?: number, stopTimestamp?: number): Promise<void> {
    await this.client.addVideoToPlaylist(playlistId, videoId, startTimestamp, stopTimestamp)
  }

  async blockAccount (accountName: string): Promise<void> {
    await this.client.blockAccount(accountName)
  }

  async report (reportType: string, message: string, videoId?: number, commentId?: number, accountId?: number): Promise<void> {
    const reportPayload: any = {
      reason: message,
      predefinedReasons: [reportType],
      ...(videoId !== undefined && { video: { id: videoId } }),
      ...(commentId !== undefined && { comment: { id: commentId } }),
      ...(accountId !== undefined && { account: { id: accountId } })
    }

    await this.client.report(reportPayload)
  }

  async deleteComment (id: string | number, commentId: number): Promise<void> {
    await this.client.deleteComment(id, commentId)
  }

  async notifyUserIsWatchingVideo (videoId: string, notifyUserIsWatchingVideoPayload: NotifyUserWatchingVideoRequest): Promise<void> {
    await this.client.notifyUserIsWatchingVideo(videoId, notifyUserIsWatchingVideoPayload)
  }
}
