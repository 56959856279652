import React, { Suspense, useCallback, useEffect, useState, type ReactNode } from 'react'
import './App.css'
import VideoSwiper from './components/Layouts/VideoSwiper'
import { loginPrerequisite } from './core/requests/loginPrerequisite'
import { defaultVideoQueryParams, getVideoList } from './core/requests/getVideoList'
import { type Video } from './types/videoInterface'
import { getVideoById } from './core/requests/getVideoById'
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom'
import InitialRedirect from './Router/InitialRedirectComponent'
import { login } from './core/requests/login'
import { type Account } from './types/Account'
import { getMyAccount } from './core/requests/getMyAccount'
import { Bounce, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { checkTokenFreshness } from './helpers/checkTokenFreshness'
import { TokenFreshness } from './constants/token-freshness.enum'
import ReactGA from 'react-ga'
import posthog from 'posthog-js'
import { linkBridge } from '@webview-bridge/web'
import { setToken } from './context/authSlice/actions'
import { useAppDispatch, useAppSelector } from './context/store.config'
import { type AuthToken } from './types/LoginResponse'
import { jsonParse, jsonStringify } from './utils/jsonParse'
import { setCurrentLoggedUserId } from './context/commentsSlice/actions'

ReactGA.initialize(process.env.REACT_APP_GA ?? '')
// @ts-expect-error
posthog.init(process.env.REACT_APP_POSTHOG, { api_host: process.env.REACT_APP_POSTHOG_API })

// @ts-expect-error
const bridge = linkBridge<AppBridge>({
  throwOnError: true,
  onReady: () => {
    console.log('Bridge is ready')
    const event = new Event('bridgeReady')
    document.dispatchEvent(event)
  }
})

// @ts-expect-error
bridge.setToken = (token: string) => {
  localStorage.setItem('accessToken', token)
}

function App (): ReactNode {
  const { videoId } = useParams()
  const [initialRedirectDone, setInitialRedirectDone] = useState<boolean>(false)
  const [user, setUser] = useState<Account | undefined>(undefined)
  const [videos, setVideos] = useState<Video[]>([])
  const [currentVideo, setCurrentVideo] = useState<Video>({
    id: '',
    title: '',
    description: '',
    url: '',
    userDetails: {
      id: 0,
      username: '',
      avatarUrl: '',
      name: ''
    },
    songDetails: {
      title: '',
      artist: ''
    },
    previewPath: '/lazy-static/previews/9dad7485-e1ed-4e25-b8e9-062c8e4da8d8.jpg',
    name: ''
  })
  const [parentUrl, setParentUrl] = useState<string>()
  const dispatch = useAppDispatch()
  const token = useAppSelector(state => state.authReducer.token)
  const isWellKnownPath = window.location.pathname.startsWith('/.well-known/')

  useEffect(() => {
    if (window.self !== window.top && window.parent) {
      const parlerPlayerUrl = new URL(document.referrer).origin
      setParentUrl(parlerPlayerUrl)
      window.parent.postMessage({ type: 'request_token' }, parlerPlayerUrl)
    }
  }, [])

  useEffect(() => {
    // @ts-expect-error
    const bridge = linkBridge<AppBridge>({
      onReady: async (method) => {
        console.log('Bridge is ready')
        // @ts-expect-error
        const token = await method.sendToken()
        console.log('Token received from native:', token)

        // Ensure the token is a string before setting it
        if (typeof token === 'string' && token) {
          dispatch(setToken({
            access_token: token,
            refresh_token: '',
            token_type: ''
          }))
        } else {
          console.warn('Received token is not a string:', token)
          // localStorage.removeItem('token')
          const response = await getMyAccount()
          if (response) {
            localStorage.setItem('user', JSON.stringify(response))
          }
        }
      }
    })
    // // @ts-ignore
    // bridge.sendToken().then((message: string) => console.log(message)); // Expecting Token
  }, [])

  useEffect(() => {
    // Fetch and store user account info
    const fetchAccount = async () => {
      const response = await getMyAccount()
      if (response) {
        localStorage.setItem('user', JSON.stringify(response))
      }
    }
    if (token) {
      fetchAccount()
    }
  }, [token])

  useEffect(() => {
    const receiveMessageFromIframe = (event: MessageEvent) => {
      if (parentUrl && event.origin === parentUrl && event.data.type === 'response_token') {
        console.log('receive message from parler play:', event.data)
        if (jsonStringify(token) !== jsonStringify(event.data.data)) {
          localStorage.setItem('token', jsonStringify(token))
          dispatch(setToken(event.data.data as AuthToken))
        } else {
          console.log('receive message from parler play but same:')
        }
      }
      if (event.data === 'clearTokens') {
        console.log('clearTokens', event.data.type)
        dispatch(setToken(undefined))
        setUser(undefined)
        localStorage.removeItem('token')
        dispatch(setCurrentLoggedUserId(0))
        localStorage.removeItem('user')
      }
      if (event.data.type === 'setTokens') {
        console.log('setTokens', event.data.type)
        dispatch(setToken(event.data.tokens))
        if (jsonStringify(token) !== jsonStringify(event.data.data)) {
          (async () => await getMyAccount())().then(res => {
            if (res) {
              setUser(res)
              dispatch(setCurrentLoggedUserId(res.userId))
              localStorage.setItem('user', JSON.stringify(res))
            }
          })
        } else {
          console.log('receive message from parler play but same:')
        }
      }
    }

    window.addEventListener('message', receiveMessageFromIframe)

    return () => {
      window.removeEventListener('message', receiveMessageFromIframe)
    }
  }, [parentUrl, token])

  if (isWellKnownPath) {
    return null // Return null or an empty fragment to bypass the normal flow for .well-known paths
  }

  useEffect(() => {
    // (async () => {
    //   if (process.env.REACT_APP_IS_DEVELOPMENT === 'true') {
    //     const isPrerequisiteMet = await loginPrerequisite()
    //     const tokenFreshness = checkTokenFreshness()
    //     if (isPrerequisiteMet) {
    //       if (tokenFreshness === TokenFreshness.EMPTY || tokenFreshness === TokenFreshness.VALID) {
    //         await login({ client_id: isPrerequisiteMet.client_id, client_secret: isPrerequisiteMet.client_secret, grant_type: 'password', username: '', password: '' }).then(async res => {
    //           if (res?.access_token) {
    //             dispatch(setToken(res as AuthToken))
    //             localStorage.setItem('token', jsonStringify(res))
    //             const response = await getMyAccount()
    //             if (response) {
    //               dispatch(setCurrentLoggedUserId(response.id))
    //               localStorage.setItem('user', JSON.stringify(response))
    //             }
    //           }
    //         })
    //       }

    //       if (tokenFreshness === TokenFreshness.EXPIRED) {
    //         await login({ client_id: isPrerequisiteMet.client_id, client_secret: isPrerequisiteMet.client_secret, grant_type: 'refresh_token', username: '', password: '' }).then(async res => {
    //           if (res?.access_token) {
    //             dispatch(setToken(res as AuthToken))
    //             const response = await getMyAccount()
    //             localStorage.setItem('token', jsonStringify(res))
    //             if (response) {
    //               dispatch(setCurrentLoggedUserId(response.id))
    //               localStorage.setItem('user', JSON.stringify(response))
    //             }
    //           }
    //         })
    //       }
    //     }
    //   }
    // })();
    (async () => {
      if (process.env.REACT_APP_IS_DEVELOPMENT === 'false') {
        const account = await getMyAccount()
        if (account) {
          dispatch(setCurrentLoggedUserId(account.userId))
          localStorage.setItem('user', JSON.stringify(account))
        }
      }
      let response: Video[] | undefined = []
      const tokens = localStorage.getItem('token')
      const localStorageTokens: AuthToken = jsonParse(tokens)
      // console.log('localStorageTokens && localStorageTokens.access_token && localStorageTokens.refresh_token', localStorageTokens)
      if ((localStorageTokens && localStorageTokens.access_token) || (token?.access_token)) {
        response = await getVideoList({ ...defaultVideoQueryParams, excludeAlreadyWatched: true }, true)
        // console.log('inside token if', localStorageTokens)
      } else {
        // console.log('inside else', localStorageTokens)
        response = await getVideoList(defaultVideoQueryParams, false)
      }
      if (response && response.length > 0) {
        setVideos(response)
        const currentVideo = await getVideoById(response[0].id)
        if (currentVideo) setCurrentVideo(currentVideo)
      }
      // else {
      //     // If user is logged in and have watched all bursts, give him random videos by Fisher-Yates algorithm, this will have no effect as bursts content grows.
      //     console.log('{ ...defaultVideoQueryParams, sort: \'best\' }', { ...defaultVideoQueryParams, sort: 'best' })
      //     response = await getVideoList({ ...defaultVideoQueryParams, sort: 'best', excludeAlreadyWatched: false }, true)
      // }
    })()
  }, [videoId])

  return (
    <BrowserRouter>
      <div className="bg-black overflow-hidden justify-center items-center min-h-screen flex flex-col">
        <div className="w-full max-w-screen-lg sm:pl-60 ipad-mini:pl-9 md:lg:pl-60 lg:pl-60">
          <Suspense fallback={<div>Loading...</div>}>
            {
              videos.length > 0 && currentVideo.id !== '' && (
                <>
                  {
                    videos.length > 0 && currentVideo.id !== '' && !initialRedirectDone && (
                      <InitialRedirect videos={videos} setInitialRedirectDone={setInitialRedirectDone} />
                    )
                  }
                  <Routes>
                    <Route path="/burst/:videoId" element={<VideoSwiper videos={videos} currentVideo={currentVideo} />} />
                  </Routes>
                </>
              )
            }
          </Suspense>
        </div>
        <ToastContainer
          position="bottom-center"
          autoClose={1200}
          hideProgressBar
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          theme="dark"
          transition={Bounce}
        />
      </div>
    </BrowserRouter>
  )
}

export default App
