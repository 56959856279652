import React, { useState, type ReactNode, useEffect, useRef } from 'react'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import { type Video } from '../../types/videoInterface'
import { notifyUserWatchingVideo } from '../../core/requests/notifyUserWatchingVideo'
import { AppSettingsConstants } from '../../constants/app.enums'
import { type DefaultSettingsSilentMobile } from '../../types/RNWebView.types'
import { jsonParse } from '../../utils/jsonParse'
import { useSwiperSlide } from 'swiper/react'
import type Player from 'video.js/dist/types/player'

interface BurstProps {
  currentIndex: any
  options: any
  previewPath?: string
  autoplay: boolean
  currentVideo: Video | null
}

export const Burst = ({ currentIndex, options, previewPath, autoplay, currentVideo }: BurstProps): ReactNode => {
  const videoRef = React.useRef<HTMLDivElement>(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isMuted, setIsMuted] = useState(false)
  const [currentTime, setCurrentTime] = useState<number>(0)
  const swiperSlide = useSwiperSlide()
  const playerRef = useRef<Player>()

  useEffect(() => {
    // Check SessionStorage after the component mounts to respect the persisted state
    const savedMuteState = sessionStorage.getItem('isMuted')
    if (savedMuteState) {
      setIsMuted(savedMuteState === 'true')
    }
  }, [])

  useEffect(() => {
    const recieveMessageFromMobileApp = (event: MessageEvent) => {
      if (typeof event.data === 'string') {
        try {
          const data: DefaultSettingsSilentMobile = JSON.parse(event.data)
          if (data && data.action === AppSettingsConstants.BURSTS_ACCESSED_FROM_MOBILE_APP) {
            setIsMuted(data.isMuted)
            sessionStorage.setItem('isMuted', data.isMuted.toString())
          }
        } catch (error) {
          console.log('ERR::42::BURST:COMM', error)
        }
      }
    }

    window.addEventListener('message', recieveMessageFromMobileApp)

    return () => {
      window.removeEventListener('message', recieveMessageFromMobileApp)
    }
  }, [])

  useEffect(() => {
    if (currentTime > 0) {
      notifyUserWatchingVideo(currentVideo!.id, {
        currentTime: parseInt((currentTime).toString()),
        viewEvent: ''
      })
    }
  }, [currentIndex, currentVideo, currentTime])

  const { onReady, onVideoEnd } = options // Extract onVideoEnd from options
  const thumbnail = `${process.env.REACT_APP_PARLER_PLAY_BASE_URL}${previewPath}`

  React.useEffect(() => {
    if (!videoRef.current) return

    const videoElement = document.createElement('video-js')
    videoRef.current.appendChild(videoElement)

    const playerOptions = {
      ...options,
      controls: false, // Make sure controls are set according to your requirements
      fluid: true,
      autoplay: swiperSlide.isActive && autoplay,
      fullscreen: false,
      playsinline: true,
      muted: isMuted
    }

    // Initialize Video.js player
    const player = videojs(videoElement, playerOptions, function onPlayerReady (this: any) {
      this.poster(thumbnail)
      videoElement.setAttribute('playsinline', '')
      videoElement.setAttribute('webkit-playsinline', '')

      // Adjusted play button logic
      player.on('play', () => {
        setIsPlaying(true)
        const videoDuration = this.duration()
        setCurrentTime(videoDuration)
        const savedMuteState = sessionStorage.getItem('isMuted')
        if (savedMuteState) {
          setIsMuted(savedMuteState === 'true')
          player.muted(savedMuteState === 'true')
        }
      })
      player.on('pause', () => { setIsPlaying(false) })

      // Adjusted volume button logic
      // @ts-expect-error
      player.on('volumechange', () => { setIsMuted(player.muted()) })
      onReady?.(this)
    })

    playerRef.current = player

    // Play button logic
    // const customPlayButton = document.getElementById('customPlayButton')
    // const handlePlayClick = () => {
    //   console.log('click Play')
    //   if (autoplay) {
    //     console.log(autoplay)
    //     if (player.paused()) {
    //       console.log('playing video play', autoplay)
    //       player.play()
    //     } else {
    //       console.log('playing video pause', autoplay)
    //       player.pause()
    //     }
    //   }
    // }
    // // @ts-expect-error
    // customPlayButton.addEventListener('click', handlePlayClick)

    // Volume button logic
    // const customVolumeButton = document.getElementById('customVolumeButton')
    // const handleVolumeClick = () => {
    //   // console.log('click Volume')
    //   // if (autoplay) {
    //   //   console.log(autoplay)
    //   //   const newMutedState = !player.muted()
    //   //   player.muted(newMutedState)
    //   //   // Update state and SessionStorage
    //   //   console.log('playing sound', autoplay)
    //   //   setIsMuted(newMutedState)
    //   //   sessionStorage.setItem('isMuted', newMutedState.toString())
    //   // }
    // }
    // // @ts-expect-error
    // // customVolumeButton.addEventListener('click', handleVolumeClick)

    // Cleanup
    return () => {
      if (player && !player.isDisposed()) {
        // // @ts-expect-error
        // customPlayButton.removeEventListener('click', handlePlayClick)
        // // @ts-expect-error
        // customVolumeButton.removeEventListener('click', handleVolumeClick)
        player.dispose()
      }
    }
  }, [options, onReady, onVideoEnd]) // Depend on onVideoEnd to recreate player if it changes

  const handlePlayClick = () => {
    if (!playerRef.current) return
    console.log('playing sound', autoplay)
    if (playerRef.current.paused()) {
      console.log('playing sound', autoplay)
      playerRef.current.play()
    } else {
      console.log('playing sound', autoplay)
      playerRef.current.pause()
    }
  }

  const handleVolumeClick = () => {
    if (!playerRef.current) return
    console.log(autoplay)
    const newMutedState = !playerRef.current.muted()
    playerRef.current.muted(newMutedState)
    // Update state and SessionStorage
    console.log('playing sound', autoplay)
    setIsMuted(newMutedState)
    sessionStorage.setItem('isMuted', newMutedState.toString())
  }

  return (
    <div key={currentIndex}>
      <div data-vjs-player>
          <div ref={videoRef} />
          <button id="customPlayButton" onClick={handlePlayClick} style={{ border: 'none', background: 'none', zIndex: 999999999 }}>
            <img
              src={isPlaying ? '/Pause.svg' : '/PlayButton.svg'}
              alt={isPlaying ? 'Pause' : 'Play'}
              style={{ width: '24px', height: '24px', zIndex: 999999999 }}
            />
          </button>
          <button id="customVolumeButton" onClick={handleVolumeClick} style={{ border: 'none', background: 'none', zIndex: 999999999 }}>
            <img
              src={isMuted ? '/SpeakerSlash.svg' : '/SpeakerHigh.svg'}
              alt={isMuted ? 'Unmute' : 'Mute'}
              style={{ width: '24px', height: '24px', zIndex: 999999999 }}
            />
          </button>
      </div>
    </div>
  )
}

export default Burst
