import { TUBE_BASE_URL } from '../../constants/app.enums'
import { type CommentResponse } from '../../types/CommentResponse'
import { type Comment } from '../../types/videoInterface'

export const mapVideoCommentsToCommentProps = (commentResponse?: CommentResponse) => {
  const baseUrl = process.env.REACT_APP_PARLER_PLAY_BASE_URL
  if (commentResponse) {
    const comments: Comment[] = commentResponse.data.filter(x => !x.isDeleted).map(comment => {
      const commentObject: Comment = {
        id: comment.id,
        // @ts-expect-error
        userDetails: {
          id: comment?.account?.userId,
          username: comment?.account?.name,
          avatarUrl: comment?.account?.avatars.length > 0 ? `${process.env.REACT_APP_PARLER_PLAY_BASE_URL}${comment.account.avatars[0].path}` : '',
          profileUrl: comment?.account?.url?.includes(TUBE_BASE_URL) ? comment?.account?.url.replaceAll(TUBE_BASE_URL, baseUrl!) : comment?.account?.url
        },
        timeAgo: comment.createdAt,
        commentText: comment.text,
        likeCount: 0
      }

      return commentObject
    })
    return comments
  }

  return undefined
}
