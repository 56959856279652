/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-trailing-spaces */
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import CustomToast from './DiscreteToast'
import 'react-toastify/dist/ReactToastify.css'

export interface ShareDialogProps {
    isOpen: boolean
    onCopyLink: () => void
    closeDialog: () => void
    videoId: string | undefined
}

const ShareDialog: React.FC<ShareDialogProps> = ({ isOpen = false, videoId, onCopyLink, closeDialog }) => {
  const [isLinkCopied, setIsLinkCopied] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [currentUrl, setCurrentUrl] = useState('')

  useEffect(() => {
    setCurrentUrl(`${process.env.REACT_APP_PARLER_PLAY_BASE_URL}/videos/bursts/${videoId}`)
  }, [videoId])

  const toggleAccordion = () => {
    setExpanded(!expanded)
  }

  const handleCloseDialog = () => {
    closeDialog()
    setIsLinkCopied(false)
  }

  const handleCopyLink = () => {
    onCopyLink()
    setIsLinkCopied(true)
    toast(<CustomToast text="Copied to clipboard!" />, { 
        position: 'bottom-center',
        autoClose: 1000,
        style: {
            position: 'absolute', 
            left: 0,
            right: 0, 
            marginLeft: 'auto',
            marginRight: 'auto',
            zIndex: 999999999,
            width: '242px',
            bottom: 45,
            background: 'none',
            boxShadow: 'none'
        },
        toastId: 'copy-toast',
        closeButton: false
    })
  }

  return (
    <div className={`fixed inset-0 z-10 overflow-y-auto ${isOpen ? '' : 'hidden'}`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex justify-center items-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {/* Background overlay, simulate modal effect */}
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" onClick={handleCloseDialog}></div>
        {/* Modal panel */}
        <div className="inline-block align-bottom bg-[#262626] rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 w-full">
          <div className="sm:flex sm:items-start">
            <div className="mt-3 text-center sm:mt-0 sm:ml-0 sm:text-left w-full">
              <div className='flex justify-between items-center pb-2'>
                <div className='d-flex justify-content-start'>
                    <h3 className="text-lg leading-6 font-semibold text-white" id="modal-title">
                        Share
                    </h3>
                </div>
                <button 
                    className='d-flex justify-content-end focus:outline-none text-white bg-transparent hover:bg-white/10 rounded-full p-2'
                    onClick={handleCloseDialog}
                >
                        <img src={'/CloseIcon.svg'} alt="Close dialog"/>
                </button>
              </div>
              <div className="mt-2">
                <div className="text-sm text-white">
                    {/* Added gap for spacing */}
                    {/* <div className="flex justify-start items-center gap-4"> 
                       <div className='flex items-center flex-col'>
                             <button className="focus:outline-none text-white bg-[#E50038] hover:bg-[#E50038]/90 rounded-full p-3">
                                <img src='/LinkIcon.svg' alt='Link to video' />
                            </button>
                            <p className='font-normal text-xs pt-2'>Link</p>
                       </div>
                        <div className='flex items-center flex-col'>
                            <button className="focus:outline-none text-white bg-[#0A0A0A] hover:bg-white/10 rounded-full p-3">
                                <img src='/EmbedIcon.svg' alt='Embed Video' />
                            </button>
                            <p className='font-normal text-xs pt-2'>Embed</p>
                        </div>
                        <div className='flex items-center flex-col'>
                            <button className="focus:outline-none text-white bg-[#0A0A0A] hover:bg-white/10 rounded-full p-3">
                                <img src='/QRCodeIcon.svg' alt='QR Code for video' />
                            </button>
                            <p className='font-normal text-xs pt-2'>QR Code</p>
                        </div>
                    </div> */}
                    <div className="flex mt-4 items-stretch">
                        <input readOnly disabled={true} className="shadow-sm p-2 flex-1 text-white bg-black border-none rounded-l-md text-base font-normal" value={currentUrl} />
                        <button className="bg-[#171717] px-4 flex items-center justify-center rounded-r-md" onClick={handleCopyLink}>
                            <img src={`${isLinkCopied ? '/CheckIcon.svg' : '/CopyIcon.svg'}`} alt='Copy link' />
                        </button>
                    </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="mt-5 sm:mt-6"> */}
            {/* <button type="button" className="text-white hover:text-white" onClick={toggleAccordion}>
              <div className='flex justify-content-start align-items-center'>
                <p className='font-semibold text-sm'>Customize share settings</p>
                <img className='pl-2 text-sm font-semibold' src={`${expanded ? '/UpArrow.svg' : '/DownArrow.svg'}`} alt='Expand Settings' />
              </div>
            </button> */}
            {/* Accordion content goes here, initially empty as per instruction */}
            {/* <div id="accordion-content" className="mt-4"> */}
              {/* Content will be toggled by the state */}
            {/* </div> */}
          {/* </div> */}
        </div>
      </div>
    </div>
  )
}

export default ShareDialog
