import React, { useState } from 'react'
import { type Video } from '../../types/videoInterface'
import { AppSettingsConstants } from '../../constants/app.enums'
import { type ProfileLinkType } from '../../types/RNWebView.types'
import { isMobile } from 'react-device-detect'

const VideoDetails: React.FC<Video> = ({ description, userDetails, songDetails }) => {
  const avatarUrl = userDetails.avatarUrl || '/DefaultUserPic.svg'
  const [seeMore, setSeeMore] = useState<boolean>(false)

  const handleSeeMoreClick = () => {
    setSeeMore(!seeMore)
  }

  const sendIsProfileUrlClickedToReactNative = () => {
    const message: ProfileLinkType = {
      action: AppSettingsConstants.IS_PROFILE_LINK_CLICKED,
      burst: window.location.href,
      profileUrl: userDetails.profileUrl ?? ''
    }
    console.log('message', message)
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView?.postMessage(JSON.stringify(message))
    }
  }

  return (
    <div className="p-4 rounded-lg">
      <div className="user-details flex items-center mb-4">
        <a href={userDetails.profileUrl} target='_self' onClick={sendIsProfileUrlClickedToReactNative}>
          <img
            src={avatarUrl}
            alt={`${userDetails.username}'s avatar`}
            className="w-12 h-12 rounded-full mr-4"
          />
        </a>
        {/* Username */}
        <a href={userDetails.profileUrl} target='_self' onClick={sendIsProfileUrlClickedToReactNative}>
          <span className="text-white font-bold text-sm md:text-sm lg:text-base leading-5 font-open-sans">
            @{userDetails.username}
          </span>
        </a>
        {/* Subscribe button */}
        {/* <button className="rounded-full bg-white text-black font-bold text-xs font-open-sans py-2 px-2 ml-4">
          Subscribe
        </button> */}
      </div>
      {/* Video description */}
      <div style={{ width: '100%' }} onClick={handleSeeMoreClick}>
        <p className={'text-white text-sm md:text-sm lg:text-base leading-5 font-open-sans'}>
          {!seeMore && isMobile ? description?.slice(0, 50) : !seeMore && !isMobile ? description?.slice(0, 100) : description}
          <span className='font-bold'>{description?.length >= 50 && !seeMore ? ' ...see more' : '' }</span>
        </p>
      </div>
      {/* Conditional music rendering */}
      {/* {songDetails && (
        <div className="flex items-center mt-4">
          <img src="/MusicNotesSimple.svg" alt="Music Note" className="w-4 h-4 mr-2" />
          <div className="text-white text-shadow font-open-sans text-sm md:text-sm lg:text-base">
            <p>{'Original music'} - {userDetails.username}</p>
          </div>
        </div>
      )} */}
    </div>
  )
}

export default VideoDetails
