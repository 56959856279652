import React, { KeyboardEvent, useRef } from 'react'
import Burst from '../UI/Burst'
import VideoDetails from '../UI/VideoDetails'
import Engagement from '../UI/Engagement'
import { type BurstWrapperProps } from '../../types/videoInterface'
import { isDesktop, isMobile } from 'react-device-detect'

const BurstWrapper: React.FC<BurstWrapperProps> = ({ currentIndex, src, currentVideo, myRatingOfVideo, onVideoEnd, comments, user, onRateVideo, setCommentThreadText, loading, autoplay }) => {
  const thumbnail = '/Play-loading-screen.png'
  const VideoSkeleton = () => (
    <img src={thumbnail} alt="Loading..." className="mobile-full-height" />
  )

  const videoJsOptions = {
    playsinline: true,
    loop: true,
    controls: true,
    responsive: true,
    fluid: true,
    aspectRatio: '9:16',
    controlBar: {
      playToggle: false,
      volumePanel: false,
      currentTimeDisplay: false,
      timeDivider: false,
      durationDisplay: false,
      progressControl: false,
      liveDisplay: false,
      seekToLive: false,
      remainingTimeDisplay: false,
      customControlSpacer: false,
      playbackRateMenuButton: false,
      chaptersButton: false,
      descriptionsButton: false,
      subsCapsButton: false,
      audioTrackButton: false,
      pictureInPictureToggle: false,
      fullscreenToggle: false,
      // Additional controls not mentioned in your list but might be part of the control bar
      skipBackward: false,
      skipForward: false
    },
    fullscreen: false,
    userActions: {
      doubleClick: false,
      hotkeys: false
    },
    disablePictureInPicture: true,
    sources: [
      {
        src,
        type: 'video/mp4'
      }
    ]
  }

  return (
    <div className="relative flex flex-col md:flex-row items-start sm:pl-3 ipad-mini:pl-0 ipad-mini:w-[90vw]" style={{ maxHeight: '100vh', maxWidth: '65vh' }}>
      {/* Video container */}
        <div className={`${isDesktop ? 'sm:w-4/5' : 'w-full'}`}>
        {loading
          ? <VideoSkeleton />
          : (
            <Burst key={currentIndex} currentIndex={currentIndex} options={videoJsOptions} autoplay={autoplay} previewPath={currentVideo?.previewPath} currentVideo={currentVideo} />
            )}
          {/* Video Details */}
          {currentVideo && (
              <div className="absolute bottom-10 ipad-mini:bottom-10 sm:bottom-0" style={{ width: '80%' }}>
                <VideoDetails
              title={currentVideo.title}
              description={currentVideo.description}
              userDetails={currentVideo.userDetails}
              songDetails={currentVideo.songDetails} id={''} url={''}
              previewPath={currentVideo.previewPath} name={''} />
              </div>
          )}
      </div>
      {/* Engagement - Conditionally use 'absolute' on mobile to overlay, 'static' for normal flow on larger screens */}
      {/* <div className={'absolute right-20 w-40 h-full md:static md:w-auto sm:pb-5'}> */}
        <Engagement
          myRatingOfVideo={myRatingOfVideo}
          likeCount={currentVideo?.likeCount ?? 0}
          commentCount={comments?.length ?? 0}
          comment={comments}
          user={user}
          commentProps={[]}
          onRateVideo={onRateVideo}
          setCommentThreadText={setCommentThreadText}
          currentVideoId={currentVideo?.id}
          currrentVideoUser={currentVideo?.userDetails.name}
          currentVideoUserID={currentVideo?.userDetails.id}
        />
      {/* </div> */}
    </div>
  )
}

export default BurstWrapper
