import { TUBE_BASE_URL } from '../../constants/app.enums'
import { type User as AccountUser } from '../../types/Account'
import { type User } from '../../types/videoInterface'

export const mapAccountToUser = (account: AccountUser) => {
  const baseUrl = process.env.REACT_APP_PARLER_PLAY_BASE_URL
  const user: User = {
    id: account?.account?.id,
    username: account?.account?.name,
    avatarUrl: account?.account?.avatars.length > 0 ? `${process.env.REACT_APP_PARLER_PLAY_BASE_URL}${account?.account?.avatars[0].path}` : '/DefaultUserPic.svg',
    profileUrl: account?.account?.url?.includes(TUBE_BASE_URL) ? account?.account?.url.replaceAll(TUBE_BASE_URL, baseUrl!) : account?.account?.url,
    name: account?.account?.name
  }
  return user
}
