/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { type LoginRequest } from '../../types/LoginRequest'
import { type LoginPrerequisiteResponse } from '../../types/LoginPrerequisiteResponse'
import { ApiClient } from '../api/apiClient'
import { type Account } from '../../types/Account'
import { type LoginResponse } from '../../types/LoginResponse'
import uriObject from '../uris/index.uri'
import { type IApiClientToken } from '../api/Types'

export interface IAuthApiClient extends IApiClientToken {
  loginPrerequisite: () => Promise<LoginPrerequisiteResponse | undefined>
  login: (loginRequest: LoginRequest) => Promise<LoginResponse | undefined>
  loginRefreshToken: (loginRequest: LoginRequest) => Promise<LoginResponse | undefined>
  getMyAccount: () => Promise<Account | undefined>
}

export class AuthApiClient extends ApiClient implements IAuthApiClient {
  async loginRefreshToken (loginRequest: LoginRequest): Promise<LoginResponse | undefined> {
    const data = {
      client_id: loginRequest.client_id,
      client_secret: loginRequest.client_secret,
      grant_type: 'refresh_token',
      refresh_token: this.getToken()?.refresh_token
      // password: '987654cba',
      // username: 'operations+2@parler.com'
    }
    try {
      const response = await this.postWithHeaders<any, LoginResponse>(`${this.apiBase}${uriObject.authUris.login}`, data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })

      return response
    } catch (error) {
      console.error('ERR::LOGIN', error)
    }
  }

  async loginPrerequisite (): Promise<LoginPrerequisiteResponse | undefined> {
    try {
      const response = await this.get<LoginPrerequisiteResponse>(`${this.apiBase}${uriObject.authUris.loginPrerequisite}`)
      localStorage.setItem('clientSecrets', JSON.stringify(response))
      return response
    } catch (error) {
      console.error('ERR::LOGINPREREQUISITE', error)
    }
  }

  async login (loginRequest: LoginRequest): Promise<LoginResponse | undefined> {
    const data = {
      client_id: loginRequest.client_id,
      client_secret: loginRequest.client_secret,
      grant_type: 'password',
      password: '987654cba',
      username: 'operations+2@parler.com'
    }

    // data.append('client_id', loginRequest.client_id)
    // data.append('client_secret', loginRequest.client_secret)
    // data.append('grant_type', 'password')
    // data.append('password', 'Vinica123')
    // data.append('username', 'stefan_nikolovski')

    try {
      const response = await this.postWithHeaders<any, LoginResponse>(`${this.apiBase}${uriObject.authUris.login}`, data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })

      return response
    } catch (error) {
      console.error('ERR::LOGIN', error)
    }
  }

  async getMyAccount (): Promise<Account | undefined> {
    try {
      const response = await this.get<Account>(`${this.apiBase}${uriObject.authUris.account}`)
      return response
    } catch (error) {
      console.error('ERR::GETMYACC', error)
      throw error
    }
  }
}

export class AuthService {
  readonly client: IAuthApiClient

  constructor (client: IAuthApiClient) {
    this.client = client
  }

  async loginPrerequisite (): Promise<LoginPrerequisiteResponse | undefined> {
    return await this.client.loginPrerequisite()
  }

  async login (loginRequest: LoginRequest): Promise<LoginResponse | undefined> {
    let response = null

    if (loginRequest.grant_type === 'refresh_token') {
      response = await this.client.loginRefreshToken(loginRequest)
    } else {
      response = await this.client.login(loginRequest)
    }

    return response
  }

  async getMyAccount (): Promise<Account | undefined> {
    const token = this.client.getToken()
    // Check if token is undefined or null
    if (!token?.access_token) {
      const emptyObject: Account = {
        id: 0,
        url: '',
        name: '',
        host: '',
        avatars: [],
        hostRedundancyAllowed: false,
        followingCount: 0,
        followersCount: 0,
        createdAt: '',
        banners: [],
        displayName: '',
        description: null,
        updatedAt: '',
        userId: 0
      }
      return emptyObject
    }
    return await this.client.getMyAccount()
  }
}
