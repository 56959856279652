import type React from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { type Video } from '../types/videoInterface'

interface InitialRedirectProps {
  videos: Video[]
  setInitialRedirectDone: (done: boolean) => void
}

const InitialRedirect: React.FC<InitialRedirectProps> = ({ videos, setInitialRedirectDone }) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (videos.length > 0) {
      navigate(`/burst/${videos[0].id}`)
      setInitialRedirectDone(true)
    }
  }, [videos, navigate])

  return null
}

export default InitialRedirect
