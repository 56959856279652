/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-trailing-spaces */
import React, { useEffect, useRef, useState, type KeyboardEvent } from 'react'
import Comment from './Comment'
import {
  type Comment as ThreadComment,
  type CommentsSectionProps
} from '../../types/videoInterface'
import { isDesktop, isMobile } from 'react-device-detect'
import { getVideoComments } from '../../core/requests/getVideoComments'
import { mapVideoCommentsToCommentProps } from '../../core/mappers/mapVideoCommentsToCommentProps'
import { videoService } from '../../core/services'
import { toast } from 'react-toastify'
import CustomToast from './DiscreteToast'
import 'react-toastify/dist/ReactToastify.css'
import Button from './Button'
import { AppSettingsConstants } from '../../constants/app.enums'
import { type ProfileLinkType } from '../../types/RNWebView.types'
import LoginDialog from './LoginDialog'
import { useAppSelector } from '../../context/store.config'
import { getTokens } from '../../context/authSlice/selectors'
import { Navigate } from 'react-router-dom'

const CommentsSection: React.FC<CommentsSectionProps> = ({
  comments,
  user,
  onCloseComments,
  setCommentThreadText,
  showComments,
  currentVideoId
}) => {
  const [isLoginDialogOpen, setIsLoginDialogOpen] = useState<boolean>(false)
  const [disabledInput, setDisableInput] = useState<boolean>(false)
  const token = useAppSelector(getTokens)

  // A local state to manage the input from the comment box
  const [commentText, setCommentText] = React.useState('')
  const [threadComments, setThreadComments] =
    useState<ThreadComment[]>(comments)

  const [isReportFormVisible, setIsReportFormVisible] = useState(false)
  const [openCommentId, setOpenCommentId] = useState<number | null>(null)
  const [message, setMessage] = useState('')
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 })
  const commentSectionRef = useRef<HTMLDivElement>(null)

  const sendIsProfileUrlClickedToReactNative = () => {
    const message: ProfileLinkType = {
      action: AppSettingsConstants.IS_PROFILE_LINK_CLICKED,
      burst: window.location.href,
      profileUrl: user.profileUrl ?? ''
    }
    console.log('message', message)
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView?.postMessage(JSON.stringify(message))
    }
  }

  const redirectUserToLoginOnMobile = () => {
    const message: string = AppSettingsConstants.LOGIN_REDIRECT_FROM_COMMENT_CLICK
    console.log('message', message)
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView?.postMessage(message)
      setIsLoginDialogOpen(false)
      setDisableInput(false)
    }
  }

  const handleLoginClick = () => {
    if (isMobile && window.ReactNativeWebView) {
      redirectUserToLoginOnMobile()
    } else {
      window.parent.location.href = `${process.env.REACT_APP_PARLER_PLAY_BASE_URL}/login`
    }
  }

  const handleReport = () => {
    setIsReportFormVisible(true)
    setOpenCommentId(null)
  }

  const onReportClose = () => { setIsReportFormVisible(false) }

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCommentText(event.target.value)
  }
  useEffect(() => {
    if (showComments) {
      document.body.style.overflow = 'hidden'

      // Fetch the latest comments when the comment section is shown
      const fetchComments = async () => {
        try {
          // @ts-expect-error
          const commentsResponse = await getVideoComments(currentVideoId)
          const mappedComments = mapVideoCommentsToCommentProps(commentsResponse)
          setThreadComments(mappedComments ?? [])
        } catch (error) {
          console.error('Failed to fetch comments:', error)
        }
      }

      fetchComments()
    }
  }, [showComments, currentVideoId])

  useEffect(() => {
    if (showComments) {
      document.body.style.overflow = 'hidden'
    }
  }, [showComments])

  const handleComment = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      setCommentThreadText(commentText)
      setCommentText('')
      const date = new Date().toString()
      const newComment: ThreadComment = {
        id: Math.random(),
        userDetails: {
          id: user.id,
          username: user.username,
          avatarUrl: user.avatarUrl,
          profileUrl: user.profileUrl,
          name: ''
        },
        timeAgo: date,
        commentText,
        likeCount: 0,
        user
      }
      setThreadComments((prev) => [newComment, ...prev])
    }
  }

  const handleTouchMove = (e: React.TouchEvent) => {
    e.stopPropagation()
  }

  const handleScroll = (e: React.UIEvent) => {
    // e.stopPropagation()
  }

  const handleModalClose = () => {
    setOpenCommentId(null)
  }

  const toggleModal = (id: React.SetStateAction<number | null>, event: { clientY: any, clientX: any } | undefined) => {
    if (event) {
      const { clientY, clientX } = event
      const offsetTop = (!isMobile && !isDesktop) ||
      (window.innerWidth >= 640 && window.innerWidth <= 744 && window.innerHeight <= 1133) ? 360 : isMobile ? 260 : -40
      const offsetLeft = (!isMobile && !isDesktop) || 
      (window.innerWidth >= 640 && window.innerWidth <= 744 && window.innerHeight <= 1133) ? 220 : isMobile ? 220 : 230

      if (commentSectionRef.current) {
        const commentSectionRect = commentSectionRef.current.getBoundingClientRect()
        const top = clientY - commentSectionRect.top - offsetTop
        const left = clientX - commentSectionRect.left - offsetLeft

        setOpenCommentId(openCommentId === id ? null : id)
        setModalPosition({ top, left })
      }
    }
  }

  const handleInputClick = (e: any) => {
    if (token === undefined) {
      e.stopPropagation()
      setDisableInput(true)
      setIsLoginDialogOpen(true)
    }
  }

  const handleCloseLoginDialog = (e: any) => {
    e.stopPropagation()
    setDisableInput(false)
    setIsLoginDialogOpen(false)
  }

  const handlePostComment = () => {
    setCommentThreadText(commentText)
    setCommentText('')
    const date = new Date().toString()
    const newComment: ThreadComment = {
      id: Math.random(),
      userDetails: {
        id: user.id,
        username: user.username,
        avatarUrl: user.avatarUrl,
        profileUrl: user.profileUrl,
        name: ''
      },
      timeAgo: date,
      commentText,
      likeCount: 0,
      user
    }
    setThreadComments((prev) => [newComment, ...prev])
  }

  return (
    <>
      {!isMobile ? (
        <div className='comment-wrapper w-full h-full' ref={commentSectionRef}>
          <div className='overlay' onClick={onCloseComments}></div>
          <div
            className={`comment-container bg-hashtag p-4 rounded-r-lg rounded-l-none flex flex-col space-y-4 sm:mt-0 ${showComments ? 'sm:absolute sm:h-full w-[22vw] z-50000' : ''}`}
            onMouseDown={(e) => { e.stopPropagation() }}
            onMouseUp={(e) => { e.stopPropagation() }}
            onTouchStart={(e) => { e.stopPropagation() }}
            onTouchEnd={(e) => { e.stopPropagation() }}
            onTouchMove={handleTouchMove}
            onScroll={handleScroll} // Added event handlers here
          >
            <div className='flex items-center justify-between'>
              <div className='text-white font-bold'>{`${threadComments.length} Comments`}</div>
              <div className='flex items-center space-x-2'>
                {/* Dropdown icon SVG */}
                <img
                  src='/Dropdown.svg'
                  alt='Dropdown'
                  className='h-6 w-6 text-white cursor-pointer'
                />
                {/* X icon SVG */}
                <svg
                  onClick={onCloseComments}
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6 text-white cursor-pointer'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={2}
                    d='M6 18L18 6M6 6l12 12'
                  />
                </svg>
              </div>
            </div>
            <div className='flex-auto overflow-y-scroll no-scrollbar border-b border-white'>
              {/* Comments list */}
              {threadComments.map((comment) => (
                <Comment
                  key={comment.id}
                  comment={comment}
                  onLike={() => { }}
                  onDislike={() => { }}
                  onReply={() => { }}
                  onCloseComments={() => { }}
                  isReportFormVisible={isReportFormVisible}
                  onReportClose={onReportClose}
                  onClose={handleModalClose}
                  isVisible={openCommentId === comment.id}
                  toggleModal={(e) => { toggleModal(comment.id, e) }}
                  user={user}
                  onReport={handleReport}
                  modalPosition={modalPosition}
                  // @ts-expect-error
                  currentVideoId={currentVideoId}
                />
              ))}
            </div>
            <div className='flex items-center mt-4 space-x-0 bg-hashtag p-4 rounded-r-lg'>
              <a href={user.profileUrl} target="_self" onClick={sendIsProfileUrlClickedToReactNative}>
                <img
                  src={user?.avatarUrl ?? '/DefaultUserPic.svg'}
                  alt='Your avatar'
                  className='w-10 h-10 rounded-full'
                />
              </a>
              <div className='relative flex-1' onKeyDown={handleComment}>
                <input
                  type='text'
                  className='w-full p-2 bg-hashtag rounded-full text-white placeholder-gray-400 focus:outline-none'
                  onClick={handleInputClick}
                  disabled={disabledInput}
                  placeholder='Add a public comment...'
                  value={commentText}
                  onChange={handleCommentChange}
                />
                <div className='absolute bottom-0 left-0 right-0 h-px bg-gray-400 mx-2' />
              </div>
              <Button onClick={handlePostComment} variant={'icon'} disabled={commentText === ''} className={`${commentText === '' ? 'disabled' : ''}`}>
                <img src="/SendIcon.svg" alt="Send" className="w-6 h-6"/>
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`fixed inset-0 bg-black bg-opacity-50 ${!showComments && 'hidden'}`}
          onClick={onCloseComments}
          ref={commentSectionRef}
          style={{ pointerEvents: showComments ? 'auto' : 'none' }}
        >
          <div
            className="fixed bottom-0 left-1/2 transform -translate-x-1/2 w-full sm:w-96 bg-hashtag p-4 rounded-t-lg ipad-mini:w-full"
            style={{ maxHeight: '100vh' }}
            onClick={e => { e.stopPropagation() }}
            onTouchStart={e => { e.stopPropagation() }}
            onTouchEnd={e => { e.stopPropagation() }}
            onTouchMove={handleTouchMove}
            onScroll={handleScroll} // Added event handlers here
          >
            <div className="flex justify-between items-center text-white">
              <h2 className="text-lg font-semibold">{`${threadComments.length} Comments`}</h2>
              <svg
                onClick={onCloseComments}
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 cursor-pointer"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d='M6 18L18 6M6 6l12 12'
                />
              </svg>
            </div>
            <div
              className="mt-4 overflow-y-scroll"
              style={{ maxHeight: '55vh', height: '55vh' }}
              onClick={e => {
                // e.preventDefault()
                e.stopPropagation()
              }}
              onTouchStart={e => {
                // e.preventDefault()
                e.stopPropagation()
              }}
              onTouchEnd={e => {
                // e.preventDefault()
                e.stopPropagation()
              }}
              onTouchMove={handleTouchMove}
            // onScroll={handleScroll}
            >
              {threadComments.map((comment) => (
                <Comment
                  key={comment.id}
                  comment={comment}
                  onLike={() => { }}
                  onDislike={() => { }}
                  onReply={() => { }}
                  onCloseComments={() => { }}
                  isReportFormVisible={isReportFormVisible}
                  onReportClose={onReportClose}
                  onClose={handleModalClose}
                  isVisible={openCommentId === comment.id} // Pass visibility state
                  toggleModal={(e) => { toggleModal(comment.id, e) }}
                  user={user} onReport={handleReport}
                  modalPosition={modalPosition}
                  // @ts-expect-error
                  currentVideoId={currentVideoId}
                />
              ))}
            </div>
            <div className="mt-4 flex items-center">
              <a
                href={user.profileUrl}
                target="_self"
                onClick={sendIsProfileUrlClickedToReactNative}
              >
                <img
                  src={user.avatarUrl || '/DefaultUserPic.svg'}
                  alt='Your avatar'
                  className='w-10 h-10 rounded-full'
                />
              </a>
              <input
                type='text'
                className='flex-1 ml-4 p-2 bg-hashtag rounded-full text-white placeholder-gray-400 focus:outline-none'
                placeholder='Add a public comment...'
                value={commentText}
                // onClick={handleInputClick}
                disabled={disabledInput}
                onClick={handleInputClick}
                onChange={handleCommentChange}
                onKeyDown={handleComment}
              />
              <Button onClick={handlePostComment} variant={'icon'} disabled={commentText === ''} className={`${commentText === '' ? 'disabled' : ''}`}>
                <img src="/SendIcon.svg" alt="Send" className="w-6 h-6" />
              </Button>
            </div>
          </div>
        </div>
      )}
      <LoginDialog isOpen={isLoginDialogOpen} onClickLogin={handleLoginClick} closeDialog={handleCloseLoginDialog} />
    </>
  )
}

export default CommentsSection
