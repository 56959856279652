import { type AuthToken } from '../../types/LoginResponse'
import { AuthApiClient, AuthService } from './authService'
import { CommentApiClient, CommentService } from './commentThreadsService'
import { VideoApiClient, VideoService } from './videoService'

/**
 * @description This is the login prerequisite service default export and initialization
 */
const authApiClient = new AuthApiClient()
export const authService = new AuthService(authApiClient)

/**
 * @description This is the video service default export and initialization
 */
const videoApiClient = new VideoApiClient()
export const videoService = new VideoService(videoApiClient)

/**
 * @description This is the comment threads service default export and initialization
 */
const commentApiClient = new CommentApiClient()
export const commentService = new CommentService(commentApiClient)
