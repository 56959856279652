import React, { useEffect, useState } from 'react'
import { type EngagementProps } from '../../types/videoInterface'
import { formatCount } from '../../utils/formatCount'
import CommentsSection from './CommentsSection'
import { type VideoRatingResponse } from '../../types/RateVideoRequest'
import ShareDialog from './ShareDialog'
import { useParams } from 'react-router-dom'
import { AppSettingsConstants } from '../../constants/app.enums'
import MoreOptionsModal from './MoreOptionsModal'
import { videoService } from '../../core/services'
import ReportForm from './ReportForm'
import { isDesktop, isIPad13, isMobile, isTablet } from 'react-device-detect'

const Engagement: React.FC<EngagementProps> = ({
  likeCount,
  myRatingOfVideo,
  commentCount,
  comment,
  user,
  onRateVideo,
  setCommentThreadText,
  currentVideoId,
  currrentVideoUser,
  currentVideoUserID
}) => {
  const [active, setActive] = useState<string | null>(null)
  const [showComments, setShowComments] = useState(false) // State to manage the visibility of the comment section
  const [likes, setLikes] = useState(likeCount)
  const [myVideoRating, setMyVideoRating] =
    useState<VideoRatingResponse>({
      videoId: 0,
      rating: ''
    })
  const [isOpen, setIsOpen] = useState(false)
  const { videoId } = useParams()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [modalPosition, setModalPosition] = useState<{ top: number, left: number }>({ top: 0, left: 0 })
  const [message, setMessage] = useState('')
  const [isReportFormVisible, setIsReportFormVisible] = useState(false)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const modal = document.getElementById('more-options-modal')
      if (modal && !modal.contains(event.target as Node)) {
        setIsModalVisible(false)
      }
    }

    if (isModalVisible) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isModalVisible])

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const modalHeight = 150 // Adjust this value to match the modal's height
    const modalWidth = 150 // Adjust this value to match the modal's width
    const viewportHeight = window.innerHeight
    const viewportWidth = window.innerWidth

    let top = e.clientY
    let left = e.clientX

    if (e.clientY + modalHeight > viewportHeight) {
      top = e.clientY - modalHeight
    }

    if (e.clientX + modalWidth > viewportWidth) {
      left = e.clientX - modalWidth
    }

    setModalPosition({ top, left })
    setIsModalVisible(true)
  }

  const handleClose = () => {
    setIsModalVisible(false)
    setMessage('')
  }

  // To Do: Finish UI and Functionality in the future
  const handleSaveToPlaylist = async () => {
    // Add API call or other logic here
    console.log(videoId)
    try {
      // const result = await videoService.addVideoToPlaylist(playlistId, currentVideoId, 0, 0)
    } catch (err) {
      setError('Failed to add video to playlist')
      console.error(err)
    }
  }

  const handleReport = () => {
    setIsReportFormVisible(true)
    setIsModalVisible(false)
  }

  const handleReportSubmit = async (reportType: string, message: string) => {
    const videoId = currentVideoId // Replace with the actual video ID
    const commentId = 0 // We are not reporting comment
    const accountId = 0 // Replace with the actual account ID if applicable

    try {
      // @ts-expect-error
      await videoService.report(reportType, message, videoId, undefined, undefined)
      setMessage('Report submitted successfully.')
    } catch (err) {
      console.error('Failed to submit report', err)
      setMessage('Failed to submit report.')
    } finally {
      setIsReportFormVisible(false)
    }
  }

  const handleMuteAccount = () => {
    // @ts-expect-error
    videoService.blockAccount(currrentVideoUser)
      .then(() => {
        setMessage('Channel was successfully muted.')
        console.log('Mute Account clicked')
      })
      .catch((err) => {
        setMessage('Failed to mute channel.')
        console.error('Failed to block account', err)
      })
  }

  useEffect(() => {
    setMyVideoRating(myRatingOfVideo)
  }, [myRatingOfVideo])

  useEffect(() => {
    setLikes(likeCount)
  }, [likeCount])

  const handleClick = (icon: string) => {
    if (icon === 'message') {
      sendMessage(!showComments)
      setShowComments(!showComments) // Toggle the visibility of the comments section
    } else {
      setActive(active === icon ? null : icon)
    }

    if (icon === 'like') {
      onRateVideo({ rating: myVideoRating.rating === 'like' ? 'none' : icon })
      if (myVideoRating.rating === 'like') {
        setLikes((prev) => prev - 1)
        setMyVideoRating({ videoId: myVideoRating.videoId, rating: 'none' })
      }
      if (
        myVideoRating.rating === 'dislike' ||
        myVideoRating.rating === 'none'
      ) {
        setMyVideoRating({ videoId: myVideoRating.videoId, rating: 'like' })
        setLikes((prev) => prev + 1)
      }
    }

    if (icon === 'dislike') {
      onRateVideo({ rating: myVideoRating.rating === 'dislike' ? 'none' : icon })
      if (myVideoRating.rating === 'dislike') {
        setMyVideoRating({ videoId: myVideoRating.videoId, rating: 'none' })
      }
      if (myVideoRating.rating === 'like' || myVideoRating.rating === 'none') {
        setMyVideoRating({ videoId: myVideoRating.videoId, rating: 'dislike' })
        if (likes !== 0) setLikes((prev) => prev - 1)
      }
    }

    if (icon === 'share') {
      setIsOpen(!isOpen)
    }
  }

  const closeDialog = () => {
    setIsOpen(false)
  }

  const copyLink = () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_PARLER_PLAY_BASE_URL}/videos/bursts/${videoId}`
    )
  }

  const sendMessage = (showComments: boolean) => {
    const message = !showComments ? AppSettingsConstants.IS_COMMENT_SECTION_CLOSED : AppSettingsConstants.IS_COMMENT_SECTION_OPEN
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(message)
    } else {
      window.postMessage(message)
    }
  }

  const closeComments = () => {
    sendMessage(false)
    setShowComments(false)
  }

  const iconClasses = (icon: string, alreadyActive: boolean) =>
    `rounded-full p-2 ${active === icon || alreadyActive ? 'bg-red-parler' : 'bg-grey-parler bg-opacity-20 sm:bg-opacity-100'}`

  const closeModal = () => { setIsModalVisible(false) }

  return (
    <div className={`${!isMobile && !showComments ? 'absolute right-0 bottom-12' : !isDesktop && !showComments ? 'ipad-mini:absolute ipad-mini:right-[115px] ipad-mini:bottom-12 ipad-mini:z-500' : ''}`}>
      {isModalVisible && (
        <div className="fixed inset-0 bg-transparent bg-opacity-50 z-0" onClick={closeModal}>
        </div>
      )}
      {!showComments && (
        <div className='absolute right-0 bottom-12 left-0 w-20 md:static md:w-auto sm:pb-5 sm:absolute flex flex-col items-center p-4 rounded-lg space-y-2 transition-section w-[40mm] ml-mobile-left ml-mobile-top'>
          <div
            onClick={() => {
              handleClick('like')
            }}
          >
            <div className='flex flex-col items-center'>
              <img
                src='/ThumbsUp.svg'
                className={iconClasses('like', myVideoRating.rating === 'like')}
                alt='Like'
              />
              <span className='text-white text-xs mt-1 md:text-sm lg:text-base'>
                {formatCount(likes)}
              </span>
            </div>
          </div>
          <div
            onClick={() => {
              handleClick('dislike')
            }}
          >
            <div className='flex flex-col items-center'>
              <img
                src='/ThumbsDown.svg'
                className={iconClasses(
                  'dislike',
                  myVideoRating.rating === 'dislike'
                )}
                alt='Dislike'
              />
              <span className='text-white text-xs mt-1 md:text-sm lg:text-base'>Dislike</span>
            </div>
          </div>
          <div
            onClick={() => {
              handleClick('message')
            }}
          >
            <div className='flex flex-col items-center'>
              <img
                src='/ChatText.svg'
                className={iconClasses('message', false)}
                alt='Message'
              />
              <span className='text-white text-xs mt-1 md:text-sm lg:text-base'>
                {formatCount(commentCount)}
              </span>
            </div>
          </div>
          <div
            onClick={() => {
              handleClick('share')
            }}
          >
            <div className='flex flex-col items-center'>
              <img
                src='/ShareFat.svg'
                className='rounded-full p-2 bg-grey-parler bg-opacity-20 sm:bg-opacity-100'
                alt='Share'
              />
              <span className='text-white text-xs mt-1 md:text-sm lg:text-base'>Share</span>
            </div>
          </div>
          <div
            onClick={(e) => {
              handleModalClick(e)
            }}
          >
            <div className='flex flex-col items-center'>
              <img
                src='/ThreeDots.svg'
                className='rounded-full p-3 bg-grey-parler bg-opacity-20 sm:bg-opacity-100 w-11 h-11'
                alt='More'
                style={{ aspectRatio: '1 / 1' }}
              />
            </div>
          </div>
          {/* <div>
            <a href={user.profileUrl} target='_blank' rel='noreferrer'>
              <img
                // TO DO make this come from the props

                src={user.avatarUrl}
                className='w-14 h-14 rounded' // Tailwind does not have a utility for exact 54x54, but w-14 and h-14 will give you 56x56
                alt='Descriptive Alt Text'
                style={{ borderRadius: '4px' }} // Add custom borderRadius style as Tailwind's 'rounded' utility might not be exactly 4px
              />
            </a>
          </div> */}
        </div>
      )}
      {showComments && (
        <CommentsSection
          comments={comment}
          user={user}
          onCloseComments={closeComments}
          setCommentThreadText={setCommentThreadText}
          commentProps={[]}
          showComments={showComments}
          currentVideoId={currentVideoId}
        />
      )}

      <ShareDialog isOpen={isOpen} onCopyLink={copyLink} closeDialog={closeDialog} videoId={videoId} />

      <MoreOptionsModal
        isVisible={isModalVisible}
        onClose={handleClose}
        onSaveToPlaylist={handleSaveToPlaylist}
        onReport={handleReport}
        onMuteAccount={handleMuteAccount}
        position={modalPosition}
        message={message}
      />

      {isReportFormVisible && (
        <ReportForm
          onClose={() => { setIsReportFormVisible(false) }}
          onSubmit={handleReportSubmit}
        />
      )}
    </div>
  )
}

export default Engagement
function setError (arg0: string) {
  throw new Error('Function not implemented.')
}
