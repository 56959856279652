import React, { useEffect, useState } from 'react'
import { type CommentProps } from '../../types/videoInterface'
import { timeAgoStringFormatter } from '../../utils/timeAgoStringFormatter'
import CommentOptionsModal from './CommentOptionsModal'
import { AppSettingsConstants } from '../../constants/app.enums'
import { type ProfileLinkType } from '../../types/RNWebView.types'

const Comment: React.FC<CommentProps & {
  isVisible: boolean
  toggleModal: (e: React.MouseEvent) => void
  onReport: () => void
  isReportFormVisible: boolean
  onReportClose: () => void
  modalPosition: { top: number, left: number }
  currentVideoId: string
}> = ({
  comment,
  onReply,
  onLike,
  onDislike,
  isVisible,
  onClose,
  onCloseComments,
  toggleModal,
  user,
  modalPosition,
  currentVideoId
}) => {
  const sendIsProfileUrlClickedToReactNative = () => {
    const message: ProfileLinkType = {
      action: AppSettingsConstants.IS_PROFILE_LINK_CLICKED,
      burst: window.location.href,
      profileUrl: comment.userDetails.profileUrl ?? ''
    }
    console.log('message', message)
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView?.postMessage(JSON.stringify(message))
    }
  }

  return (
    <div key={comment.id} className="flex items-start space-x-4 p-2 text-white">
      <a href={comment.userDetails.profileUrl} target='_self' onClick={sendIsProfileUrlClickedToReactNative}>
        <img src={comment.userDetails.avatarUrl} alt={`${comment.userDetails.username}'s avatar`} className="w-10 h-10 rounded-full" />
      </a>
      <div className="flex-1">
        <div className="flex items-center justify-between space-x-2">
          <div className="flex items-center space-x-2">
            <a href={comment.userDetails.profileUrl} target='_self' onClick={sendIsProfileUrlClickedToReactNative}>
              <h4 className="text-sm font-semibold leading-5 tracking-tight">{comment.userDetails.username}</h4>
            </a>
            <span className="text-xs font-normal leading-4 text-gray-500">{timeAgoStringFormatter(comment.timeAgo)}</span>
          </div>
          <div onClick={toggleModal} style={{ cursor: 'pointer' }}>
            <img src="/DotsThreeVertical.svg" alt="DotsThreeVertical" />
          </div>
        </div>
        <p className="mt-1 text-base font-normal leading-5">{comment.commentText}</p>
      </div>

      <CommentOptionsModal
        key={comment.id}
        comment={comment}
        isVisible={isVisible}
        onClose={onClose}
        onDislike={onDislike}
        onLike={onLike}
        onReply={onReply}
        onCloseComments={onCloseComments}
        user={user}
        modalPosition={modalPosition}
        currentVideoId={currentVideoId}
      />
    </div>
  )
}

export default Comment
