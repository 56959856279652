import React from 'react'

interface MoreOptionsModalProps {
  isVisible: boolean
  onClose: () => void
  onSaveToPlaylist: () => void
  onReport: () => void
  onMuteAccount: () => void
  position: { top: number, left: number }
  message: string
}

const MoreOptionsModal: React.FC<MoreOptionsModalProps> = ({
  isVisible,
  onClose,
  onSaveToPlaylist,
  onReport,
  onMuteAccount,
  position,
  message
}) => {
  if (!isVisible) return null

  console.log(message)

  return (
    <div
      id="more-options-modal"
      className="fixed z-500"
      style={{ top: position.top, left: position.left }}
    >
      <div className="bg-gray-800 text-white rounded-lg p-4 shadow-lg">
        {message && <div className="mb-2 text-green-500">{message}</div>}
        <ul>
          <li onClick={onReport} className="p-2 hover:bg-gray-700 cursor-pointer">Report</li>
          <li onClick={onMuteAccount} className="p-2 hover:bg-gray-700 cursor-pointer">Mute Account</li>
        </ul>
      </div>
    </div>
  )
}

export default MoreOptionsModal
