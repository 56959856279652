import React, { useState, useEffect, useRef, Fragment } from 'react'
import { type CommentProps } from '../../types/videoInterface'
import { useAppSelector } from '../../context/store.config'
import { getCurrentLoggedUserId } from '../../context/commentsSlice/selectors'
import { toast } from 'react-toastify'
import CustomToast from './DiscreteToast'
import { videoService } from '../../core/services'
import ReportForm from './ReportForm'

const CommentOptionsModal: React.FC<CommentProps & {
  onClose: () => void
  modalPosition: { top: number, left: number }
  currentVideoId: string
}> = ({
  comment,
  isVisible,
  onClose,
  onDislike,
  onReply,
  onCloseComments,
  onLike,
  user,
  modalPosition,
  currentVideoId
}) => {
  const [isUserComment, setIsUserComment] = useState(false)
  const [isReportFormVisible, setIsReportFormVisible] = useState(false)
  const modalRef = useRef<HTMLDivElement>(null)
  const currentUserId = useAppSelector(getCurrentLoggedUserId)

  useEffect(() => {
    if (user.username === comment.userDetails.username) {
      setIsUserComment(true)
    } else {
      setIsUserComment(false)
    }
  }, [user.id, comment.id])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose()
      }
    }

    if (isVisible) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isVisible, onClose])

  const handleReportSubmit = async (reportType: string, message: string) => {
    const videoId = 0 // Replace with the actual video ID
    const commentId = comment.id
    const accountId = 0 // Replace with the actual account ID if applicable

    try {
      await videoService.report(reportType, message, undefined, commentId)
      console.log(comment.id)
      toast(<CustomToast text='Report submitted successfully.' />, {
        position: 'bottom-center',
        autoClose: 1000,
        style: {
          position: 'absolute',
          left: 0,
          right: 0,
          marginLeft: 'auto',
          marginRight: 'auto',
          zIndex: 999999999,
          width: '242px',
          bottom: 45,
          background: 'none',
          boxShadow: 'none'
        },
        toastId: 'copy-toast',
        closeButton: false
      })
    } catch (err) {
      console.error('Failed to submit report', err)
      toast(<CustomToast text='Failed to submit report.' />, {
        position: 'bottom-center',
        autoClose: 1000,
        style: {
          position: 'absolute',
          left: 0,
          right: 0,
          marginLeft: 'auto',
          marginRight: 'auto',
          zIndex: 999999999,
          width: '242px',
          bottom: 45,
          background: 'none',
          boxShadow: 'none'
        },
        toastId: 'copy-toast',
        closeButton: false
      })
    } finally {
      setIsReportFormVisible(false)
    }
  }

  const handleMute = async () => {
    const accountName = comment.userDetails.username // Adjust based on your actual data structure

    try {
      await videoService.blockAccount(accountName)
      toast(<CustomToast text='Account muted successfully.' />, {
        position: 'bottom-center',
        autoClose: 1000,
        style: {
          position: 'absolute',
          left: 0,
          right: 0,
          marginLeft: 'auto',
          marginRight: 'auto',
          zIndex: 999999999,
          width: '242px',
          bottom: 45,
          background: 'none',
          boxShadow: 'none'
        },
        toastId: 'mute-toast',
        closeButton: false
      })
    } catch (err) {
      console.error('Failed to mute account', err)
      toast(<CustomToast text='Failed to mute account.' />, {
        position: 'bottom-center',
        autoClose: 1000,
        style: {
          position: 'absolute',
          left: 0,
          right: 0,
          marginLeft: 'auto',
          marginRight: 'auto',
          zIndex: 999999999,
          width: '242px',
          bottom: 45,
          background: 'none',
          boxShadow: 'none'
        },
        toastId: 'mute-toast',
        closeButton: false
      })
    }
  }

  const handleDeleteComment = async () => {
    const videoId = currentVideoId
    const commentId = comment.id
    console.log('Deleting comment:', commentId)
    console.log('From video:', videoId)

    try {
      await videoService.deleteComment(videoId, commentId)
      toast(<CustomToast text='Comment deleted successfully.' />, {
        position: 'bottom-center',
        autoClose: 1000,
        style: {
          position: 'absolute',
          left: 0,
          right: 0,
          marginLeft: 'auto',
          marginRight: 'auto',
          zIndex: 999999999,
          width: '242px',
          bottom: 45,
          background: 'none',
          boxShadow: 'none'
        },
        toastId: 'delete-toast',
        closeButton: false
      })
      onClose()
    } catch (err) {
      console.error('Failed to delete comment', err)
      toast(<CustomToast text='Failed to delete comment.' />, {
        position: 'bottom-center',
        autoClose: 1000,
        style: {
          position: 'absolute',
          left: 0,
          right: 0,
          marginLeft: 'auto',
          marginRight: 'auto',
          zIndex: 999999999,
          width: '242px',
          bottom: 45,
          background: 'none',
          boxShadow: 'none'
        },
        toastId: 'delete-toast',
        closeButton: false
      })
    }
  }

  if (!isVisible) return null
  return (
      <div ref={modalRef} style={{ top: modalPosition.top, left: modalPosition.left }} className="absolute z-50 bg-black text-white shadow-lg rounded-lg">
        <div className="relative w-full max-w-xs">
          <div className="p-4 md:p-5">
            <h3 className="text-sm mb-4 text-gray-500">Actions on comment</h3>
            {isUserComment
              ? (
                <>
                  {/* <button
                    className="flex items-center w-full text-left py-2 px-4 rounded focus:outline-none focus:shadow-outline text-sm bg-gray-500 cursor-not-allowed opacity-50"
                    onClick={() => { console.log('Edit Comment') }}
                    disabled
                  >
                    <img src="/EditPencil.svg" alt="EditPencil" />
                    Edit Comment
                  </button> */}

                  <button
                    className="flex items-center w-full text-left py-2 px-4 mt-2 hover:bg-gray-700 rounded focus:outline-none focus:shadow-outline text-sm"
                    onClick={handleDeleteComment}>
                    <img src="/Trash.svg" alt="Trash" />
                    Delete Comment
                  </button>
                </>
                )
              : (
                <>
                  <button
                    className="flex items-center w-full text-left py-2 px-4 hover:bg-gray-700 rounded focus:outline-none focus:shadow-outline text-sm"
                    onClick={() => { setIsReportFormVisible(true) }}
                  >
                    <img src="/Flag.svg" alt="Flag" />
                    Report this comment
                  </button>
                  <button
                    className="flex items-center w-full text-left py-2 px-4 hover:bg-gray-700 rounded focus:outline-none focus:shadow-outline text-sm"
                    onClick={handleMute}
                  >
                    <img src="/BellSlash.svg" alt="BellSlash" />
                    Mute this account
                  </button>
                </>
                )}
            {isReportFormVisible && (
              <ReportForm
                onClose={onClose}
                onSubmit={handleReportSubmit}
              />
            )}
          </div>
        </div>
      </div>
  )
}

export default CommentOptionsModal
