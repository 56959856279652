import type { AuthToken } from '../../types/LoginResponse'

export interface InitialAuthState {
  token: AuthToken | undefined
}

const initialToken = localStorage.getItem('token')
export const initialAuthState: InitialAuthState = {
  token: initialToken ? JSON.parse(initialToken) : undefined
}
