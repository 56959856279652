/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { type User as AccountUser } from '../types/Account'
import { type User } from '../types/videoInterface'
import { mapAccountToUser } from '../core/mappers/mapAccountToUser'

export const getAccountAsUserFromLocalStorage = () => {
  const user = localStorage.getItem('user') || '{}'

  if (user !== undefined || user !== 'undefined' || user !== null || user !== '{}') {
    const account: AccountUser = JSON.parse(user)
    if (account) {
      const user = mapAccountToUser(account)
      return user
    }
  }
  // @ts-expect-error
  const emptyObject: User = {
    id: 0,
    username: '',
    avatarUrl: ''
  }

  return emptyObject
}
