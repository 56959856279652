import React, { type MouseEventHandler, type ReactNode } from 'react'
import { ButtonVariants } from '../../constants/button.variants.enum'
import '../css/ripple.styles.css'

export interface CommonButtonProps {
  className?: string
  children: ReactNode
  onClick: MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
}

export interface ButtonProps extends CommonButtonProps {
  variant: 'button' | 'round' | 'transparent' | 'icon'
}

export interface IconButtonProps extends CommonButtonProps {
}

const Button = ({
  className,
  variant = 'button',
  children,
  disabled: isDisabled = false,
  onClick
}: ButtonProps) => {
  switch (variant) {
    case ButtonVariants.BUTTON:
      return <></>
    case ButtonVariants.ROUND:
      return <></>
    case ButtonVariants.TRANSPARENT:
      return <></>
    case ButtonVariants.ICON:
      return (
        <IconButton className={className} onClick={onClick} disabled={isDisabled}>
          {children}
        </IconButton>
      )
    default:
      return <></> // TODO: Create default classic button ButtonVariants.BUTTON
  }
}

export default Button

export const IconButton = ({
  className,
  children,
  disabled,
  onClick
}: IconButtonProps) => {
  return (
    <button className={`bg-transparent overflow-hidden rounded-full p-0 relative outline-none focus:outline-none items-center ${className} ${!disabled && 'ripple'}`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  )
}
