/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/semi */

export const shuffleVideosFisherYates = <T>(array: T[]): T[] => {
    const arr = array.slice();

    for (let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [arr[i], arr[j]] = [arr[j], arr[i]];
    }

    return arr;
}
